import axios from 'axios'
import { notification } from 'ant-design-vue';
import storageUtils from '@/utils/storage';
import auth from '@/services/auth';
import useLockRepositories from "@/composables/auth/useLockRepositories"
import router from '@/router'
import store from '@/store/index'



const request = axios.create({
  baseURL: process.env.VUE_APP_RMA_MS_API_URL,
  timeout: 60000, // that super high let see
  headers: {'Access-Control-Allow-Origin': '*', "Content-Type": "multipart/form-data"}

})

//Custom error testing
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = storageUtils.getLoginToken();
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden 403',
        description: data.message
      })
    }
    if (error.response.status === 401) {
      notification.error({
        message: 'Unauthorized 401',
        description: data.message
      })
    }
    if (error.response.status === 400) {
      // notification.error({
      //   message: 'Bad Request Please retry Continue Contact IT',
      //   description: data.message
      // })
    }
    if (error.response.status === 499) {
      storageUtils.removeAllCredential()
      
      if(data.message != ''){
        notification.error({
          message: 'Token Expire Please login Again',
          description: data.message
        })
      }
      
      if(data.redirect_uri == ''){
        setTimeout(() => {
          store.dispatch('auth/logoutOld');
        },500);
      }else{
        setTimeout(() => {
          window.location.href = data.redirect_uri
        },2000);
      }

      // try {
      //   localStorage.clear()
      //   vm.$forceUpdate();
      // } catch (e) {
      //   console.log(e)
      // }

    }
    if (error.response.status === 500) {
      notification.error({
        message: 'Server Error happen please retry later',
        description: data.message
      })
    }
  }
  return Promise.reject(error)
}

// Axios request interceptor BEFORE doing the actual request
request.interceptors.request.use(
  config => {
    const isLogged = storageUtils.isLogged();
    const loginType = storageUtils.getLoginType();
    if (isLogged) {
      config.headers['Authorization'] = 'Bearer ' + storageUtils.getLoginToken(); // Set JWT token
    }
    if(loginType == 'loginNew'){
      config.headers['loginType'] = 1;
    }else{
      delete config.headers['loginType'];
    }
    config.headers['Content-Type'] = 'application/json'

    return config;
  }, 
  errorHandler
)

// Axios request interceptor AFTER doing the actual request
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

export  default request