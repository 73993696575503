// https://webpack.js.org/guides/dependency-management/#requirecontext
const requireModule = require.context('.', false, /\.js$/)
const modules = {}

requireModule.keys().forEach(fileName => {
  if (fileName === './index.js') return
  // filter fullstops and extension 
  const moduleName = fileName.replace(/(\.\/|\.js)/g, '')
    
  // create a dynamic object with all modules
  modules[moduleName] = {
    // add namespace here
    namespaced: true,
    ...requireModule(fileName).default
    // if you have exported the object with name in the module `js` file
    // e.g., export const name = {};
    // uncomment this line and comment the above
    // ...requireModule(fileName)[moduleName]
  }
})
export default modules