import { ref, reactive } from "vue";
import useRmaRepositories from "@/composables/useRmaRepositories";
import { notification } from 'ant-design-vue';

const selectedItem = ref({});
const state = reactive({
  reprintID: "",
  reprintTrackingCode: "",
  searchText: "",
  searchedColumn: "",
  selectedRowKeys: [],
  // Check here to configure the default column
  loading: false,
  modalShowPrintLabel: false,
  modalShowRMADetail: false,
  currentShowRMA: "",
  searchInput: "",
  modalShowPreparePackage: false,
  labelUrl: "",
  packageUrl: "",
  showPrintAction: false,
  onRmaEditMode: false,
  listRmaData: [],
  statusList: [],
  tableTotal: 0,
  filteredInfo: {},
  sortedInfo: {},
  searchData: {},
  clearFiltersFunction: {},
  factoryComment: [],
  storeComment: [],
  rejectReasons: [],
  rejectReasonValue: undefined,
  isShowSign: false,
  pickupFollowUpList: [],
});
const { getComments, getRejectReasonList, getHasPuchaseRecord, getAfterSalesList } = useRmaRepositories()

export default function dataState() {
  const setSelectedItem = async (data) => {
    state.loading = true
    // 超时关闭loading效果并提示错误
    const timer = setTimeout(() => {
      state.loading = false
      notification.error({
        message: "Timeout"
      })
    }, 60000);
    data.isHasPuchaseRecords = false
    selectedItem.value = data;
    getRejectReasons()
    await getAllComments(data.id)
    await getHasPuchaseRecords(data.id)
    // 返回结果不敏感，可不用await
    if (data.rma_status_id == 15) getPickupFollowUpList(data.id)
    state.loading = false
    state.modalShowRMADetail = true;
    clearTimeout(timer)
  };
  const getAllComments = async (id) => {
    state.storeComment = await getComments(id, 1)
    state.factoryComment = await getComments(id, 2)
  };
  const getRejectReasons = async () => {
    const isShop = localStorage.getItem("usertype") === "shop"
    state.rejectReasons = await getRejectReasonList(isShop ? 'store' : 'operation')
    state.rejectReasonValue = undefined
  };
  const getHasPuchaseRecords = async (rma_item_id) => {
    selectedItem.value.isHasPuchaseRecords = await getHasPuchaseRecord(rma_item_id)
  };
  const getPickupFollowUpList = async (rma_item_id) => {
    const result = await getAfterSalesList(rma_item_id)
    state.pickupFollowUpList = result.data || []
  };
  return {
    setSelectedItem,
    selectedItem,
    state,
    getAllComments,
    getPickupFollowUpList
  };
}
