export default {
    customerStatus:{
        title: 'APM Monaco 修理リクエスト',
        apmSale: 'アフターサービス',
        customerName: 'お客様のお名前',
        shopName: 'ストア',
        shopPhone: 'ストア 電話番号',
        shopEmail: 'メールアドレス：',
        created: '作成済み',
        productReference: 'リファレンス',
        repairFee: '修理料金',
        repairReason: '修理理由',
        clickText: '修理の状況はこちらよりご確認いただけます',
        enjoyText: 'www.apm.mcでお買い物をお楽しみください',
        tipsText: '24時間年中無休でご利用いただけます',
        step1Title: 'ステップ 1',
        step2Title: 'ステップ 2',
        step3Title: 'ステップ 3',
        step4Title: 'ステップ 4',
        step5Title: 'ステップ 5',
        step6Title: 'ステップ 6',
        step1Content: 'APMブティックにてデポジットお預かり済み',
        step2Content: '修理センターへ輸送中',
        step3Content: '修理センター',
        step4Content: 'APMブティックに輸送中',
        step5Content: 'お受け取りいただけます',
        step6Content: 'お受け取り済み',
        orderID: 'ご注文番号',
        orderName: 'ご注文ID',
        trackingNumber: '追跡番号',
        userName:'お名前',
        userEmail: 'メールアドレス',
        userPhone: '電話番号',
      }
    };
    