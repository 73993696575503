export default {
    customerStatus:{
        title: 'Solicitud de reparación de APM Monaco',
        apmSale: 'Servicio Postventa',
        customerName: 'Nombre de Cliente',
        shopName: 'Tienda',
        shopPhone: 'Teléfono de la Tienda',
        shopEmail: 'Dirección E-mail',
        created: 'Creado',
        productReference: 'Referencia',
        repairFee: 'Importe de la Reparación',
        repairReason: 'Motivo de la Reparación',
        clickText: 'Haga click aquí para seguir su reparación',
        enjoyText: 'Diviértase comprando en www.apm.mc',
        tipsText: 'Estamos disponibles las 24 horas al día, 7 días a la semana',
        step1Title: 'Paso 1',
        step2Title: 'Paso 2',
        step3Title: 'Paso 3',
        step4Title: 'Paso 4',
        step5Title: 'Paso 5',
        step6Title: 'Paso 6',
        step1Content: 'Entrega en la tienda APM',
        step2Content: 'Envío al taller de reparación',
        step3Content: 'Taller de reparación',
        step4Content: 'Envío a la tienda APM',
        step5Content: 'Listo para recoger',
        step6Content: 'Recogido',
        orderID: 'Número de pedido',
        orderName: 'ID del pedido',
        trackingNumber: 'Número de seguimiento',
        userName:'Nombre',
        userEmail: 'E-mail',
        userPhone: 'Número de teléfono',
      }
  };
  