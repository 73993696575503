import router from '../router'
import useLockRepositories from "@/composables/auth/useLockRepositories"
import {OPERATION_DIVISION} from '@/store/constants'
import storageUtils from '@/utils/storage';
import { useStore } from 'vuex'

export default {
  methods: {
    // permission check function
    permission: function (permission) {
      const store = useStore()
      let find = false;
      let permissionFound = undefined;
      let workerPermissionFound = undefined;
      const perm = store.getters.userPerm

      if (storageUtils.isUser()) {
        if (perm.allPermission) {
          return arr.allPermission.map(x => x.name);
        }
        // check is permission in global object
        if (perm) {
          permissionFound = perm.find(x => x.name == `${permission}`)
          ///["#INTRANET::FRONT_view home page"]// "#RMA::ENGINE_api get rma reason"// "#RMA::ENGINE_api get rma reason"
          //console.log("found",permissionFound);
        } else { // else get it form local storage
          const arr = storageUtils.getLoginUser() ? JSON.parse(storageUtils.getLoginUser()) : [];
          permissionFound = arr.allPermission.find(x => x.name == `${permission}`)// "#RMA::ENGINE_api get rma reason"
        }

        // console.log("400",this.$permissionArr[permission]);

        if (permissionFound) {
          find = true;
          // Expermnetal Worker Permision Check
          const worker = storageUtils.getWorkerUser();
          const { isWorkerLogged } = useLockRepositories()
          const arr = JSON.parse(storageUtils.getLoginUser());
          const role = arr.roles.find(x => x.name == '#INTRANET::FRONT_factory worker')
          //console.log(90,role);
          // check the user role is a factory worker user. then hide the sidebar menu
           
          if(role && role.name == '#INTRANET::FRONT_factory worker'  && !isWorkerLogged.value){
            find = false
          }

          if (worker) {
            const worker_data = JSON.parse(worker);
            find = true
            workerPermissionFound = worker_data.Permission.find(x => x == `${permission}`)
            if (!workerPermissionFound) {
              find = false
            }
          }
        //  console.log(`Action allowed for ${permission} =>`,find)
        }
      }
      return find;
    },
    //Todo toberemoves
    isLogged: function() {
      return storageUtils.isLogged()
    },
    switchWorker: function() {
      storageUtils.removeWorkerUser()
      const { isWorkerLogged } = useLockRepositories()
      isWorkerLogged.value = false
      //router.go(-1)
      router.push('/operation/rmafactory/login')
    },
    isWorkerLogged: function() {
      const { isWorkerLogged } = useLockRepositories()
      return isWorkerLogged.value || localStorage.getItem("usertype") !== "shop"
    },
    removeArrayItemByValue: function(arr) {
      var what, a = arguments, L = a.length, ax;
      while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax= arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    }
    // isUserIsAWorker: function(){
    //   const arr = JSON.parse(storageUtils.getLoginUser());
    //   permissionFound = arr.roles.find(x => x.name == '#INTRANET::FRONT_factory worker')
    //   if(permissionFound){
    //     return true
    //   }
    //   return false
    // },


    // role: function (role) {
    //   let find = false;
    //   const roleFound = undefined;
    //   if (localStorage.getItem('rmaUser')) {
        
    //     // else get it form local storage
    //     const arr = JSON.parse(localStorage.getItem('rmaUser'));
    //     roleFound = arr.roles.find(x => x.name == `${role}`)// "#RMA::ENGINE_api get rma reason"
        
    //     if(roleFound){
    //       find = true;
    //     //  console.log(`Action allowed for ${permission} =>`,find)
    //     }
    //   }
    //   return find;
    // },
    // check user is logged or not

  }
};