export default {
    customerStatus:{
        title: 'Demande de réparation APM Monaco',
        apmSale: 'Service après-vente',
        customerName: 'Nom du client',
        shopName: 'Boutique',
        shopPhone: 'Numéro de téléphone de la boutique',
        shopEmail: 'Adresse mail',
        created: 'Créé le',
        productReference: 'Référence',
        repairFee: 'Frais de réparation',
        repairReason: 'Raison de la réparation',
        clickText: 'Cliquez ici pour suivre votre réparation',
        enjoyText: 'Découvrez nos nouveautés sur www.apm.mc',
        tipsText: 'Nous sommes disponibles 24h/24, 7j/7',
        step1Title: 'Étape 1',
        step2Title: 'Étape 2',
        step3Title: 'Étape 3',
        step4Title: 'Étape 4',
        step5Title: 'Étape 5',
        step6Title: 'Étape 6',
        step1Content: 'Dépôt à la Boutique APM',
        step2Content: 'Transit vers le centre de réparation',
        step3Content: 'Centre de réparation',
        step4Content: 'Transit vers la Boutique APM',
        step5Content: 'Prêt pour le retrait',
        step6Content: 'Récupéré',
        orderID: 'Numéro de Commande',
        orderName: 'ID Commande',
        trackingNumber: 'Numéro de suivi',
        userName:'Nom',
        userEmail: 'Email',
        userPhone: 'Téléphone',
      }
  };
  