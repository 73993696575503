const router = {
  "dashboard": "Dashboard",
  "CreateRma": "Request new RMA",
  "PreparePackage" : "Prepare Package",
  "ReceivedPackage" :  "Receive Package" ,
  "listRma":  "List all RMA"
} 
  

export default router
 