import storageUtils from '@/utils/storage';
import { getLanguage } from '@/lang/index';

const state = {
  sidebar: storageUtils.getMenuState(),
  language: getLanguage()
};
  

const mutations = {
  TOGGLE_SIDEBAR: state => {
    state.sidebar = !state.sidebar;
    if (state.sidebar) {
      Cookies.set('sidebarStatus', 1);
    } else {
      Cookies.set('sidebarStatus', 0);
    }
  },
  SET_LANGUAGE: (state, language) => {
    state.language = language;
    storageUtils.setUserLang(language);
    // Cookies.set('language', language);
  },
};

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR');
  },
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language);
  },
};

export default {
  state,
  mutations,
  actions,
};