import request from '@/utils/axios';

/// GetSingleInvoice - Search Customer Invoice 
/// Method: POST
/// Content-Type: application/json
/// params {
///     InvoiceNumber : String
/// }
/// Description: Retrieve the client invoice store in the CRM
export function GetInvoice(data) {
  return request({
    url: '/search_order',
    method: 'POST',
    timeout: 60000,
    data: data,
  });
}

/// GetCustomerDetail - Search Customer Information 
/// Method: GET
/// Content-Type: application/json
/// params {
///     mail : String
///     phone : String
///     fullname : String
/// }
/// Description: AT LEAST ONE of the three parameters is mandatory
export function GetCustomerDetail(data) {
  return request({
    url: '/search_contact',
    method: 'POST',
    data: data,
  });
}

export function GetSearchCustomerInCrm(data) {
  return request({
    url: '/verify_customer_in_crm',
    method: 'POST',
    data: data,
  });
}

export function GetCustomerRecord(params) {
  return request({
    url: '/customer/record?' + params,
    method: 'get'
  });
}

export function GetRepairHistory(params) {
  return request({
    url: '/repair_history/list?' + params,
    method: 'get'
  });
}