import { ref } from "vue";

const isWorkerLogged = ref(false);

export default  function stepform() {

  
  return {
    isWorkerLogged
  };
}
