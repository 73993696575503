
 

<template>
  <div class="lang_tag_switch">
    <span v-for="(lang, i) in langs" :key="`lang-${i}`" @click="handleLangSelect(lang)">{{ flagList[lang] }}</span>
  </div>
<!--   
  <select class="lang_switch_large" v-model="$i18n.locale">
    <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
      <span >{{ LangList[lang] }}</span> {{ flagList[lang] }}
    </option>
  </select>
  <select class="lang_switch_small" v-model="$i18n.locale">
    <option v-for="(lang, i) in langs" :key="`lang-${i}`" :value="lang">
      {{ flagList[lang] }}
    </option>
  </select> 
-->
</template>

<script>
// import { ref } from "vue";
  import {watch} from 'vue' 
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import storageUtils from '../utils/storage';
  export default {
    name: 'LangSwitch',
    data() { 
      return { langs: ['en', 'zh'] }
    },
    setup() { 
      const store = useStore()
      const { t, locale } = useI18n({ useScope: 'global' }) 
      
      const flagList = {
        en : '🇬🇧',
        zh : '🇨🇳'
      }
      
      const LangList = {
        en : 'English',
        zh : '中国語'
      }

      watch(locale, (newValue, oldValue) => {
        store.dispatch('app/setLanguage', locale.value) 
        // console.log('The new locale value is: ' + locale.value)
      }) 

      const handleLangSelect = (lang) => {
        // console.log(lang, "lang")
        locale.value = lang
        storageUtils.setUserLang(lang);
      }

      return { t, flagList, LangList, handleLangSelect }
    },
 
  }
</script>

<style>

.lang_tag_switch{
  display:inline-block;
  color: #FFF;
}
.lang_tag_switch span{
  padding:10px;
  font-size: 18px;
}

</style>
