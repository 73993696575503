import { reactive, ref, nextTick } from "vue";
import useRmaRepositories from "@/composables/useRmaRepositories"

const trackingNumber = ref("")
const pendingProductScanned = ref(0)
const addingProductScanned = ref(0)
const operationId = ref(0)
const trackingCodeDisabled = ref(false)
const completedButtonDisabled = ref(true)
const productCodeDisabled = ref(true)
const validateButtonDisabled = ref(true)
const productScanned = ref("")
const toReceivedOrToShip = ref(false)
const latestProductScan = reactive({
  product_sku: "",
  product_size: "",
  rma_id: "",
  receive_item_date: ""
}); 

export default function useTrackingRmaRepositories() {

  const { rma_tracking } = useRmaRepositories()

  const reset = () => {
    trackingNumber.value = ""
    productScanned.value = ""
    // headerState.productScan = ""
    pendingProductScanned.value = 0
    addingProductScanned.value = 0
    latestProductScan.product_sku = ""
    latestProductScan.product_size = ""
    latestProductScan.rma_id = ""
    latestProductScan.receive_item_date = ""
    latestProductScan.product_image = ""
    rma_tracking.value = []
    trackingCodeDisabled.value = false
    productCodeDisabled.value = true
    validateButtonDisabled.value = true
    completedButtonDisabled.value = true
  }

  return {
    trackingNumber,
    latestProductScan,
    pendingProductScanned,
    addingProductScanned,
    productScanned,
    operationId,
    trackingCodeDisabled,
    productCodeDisabled,
    validateButtonDisabled,
    reset,
    toReceivedOrToShip,
    completedButtonDisabled
  };
}