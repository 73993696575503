import { login, logout, authCallback, loginOld } from '@/services/auth';
import storageUtils from '@/utils/storage';
import router from '@/router'
import useLockRepositories from "@/composables/auth/useLockRepositories"
import useRmaRepositories from "@/composables/useRmaRepositories"
import useTrackingRmaRepositories from "@/composables/warehouse/useTrackingRmaRepositories"

const state = {
  id: null,
  user: null,
  token: '',
  name: '',
  subname: '',
  roles: [],
  permissions: [],
};

const mutations = {
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_SUBNAME: (state, subname) => {
    state.subname = subname;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions;
  },
};

const actions = {
  // user login
  login({ commit }, userInfo) {
    const{ query } = userInfo;
    login({ query }).then(response => {});
  },

  // user login old
  loginOld({ commit }, userInfo) {
    // const { username, password } = userInfo;

    //this is an axios instance which is already a promise
    //note that if not using axios this should be reword a bit
    loginOld(userInfo).then(response => {
      let pushTo = '/dashboard?backups=true';
      if (response.user.user_type !== "shop"){
        pushTo = '/operation/dashboard?backups=true';
      }
      storageUtils.setLoginToken(response.token)
      const userInfo = {
        allPermission : response.allPermission,
        roles : response.roles,
        user: response.user,
        userinfo : response.userinfo
      }

      storageUtils.setLoginUser(JSON.stringify(userInfo))
      // 登录成功，清除失败登录次数
      storageUtils.removeLoginTimes();
      storageUtils.removeLoginBtnDisabledTime();

      // console.log("auth.js --------------")
      // console.log(response.user)
      if (response.userinfo && response.userinfo.length > 0 && response.userinfo[0].data) {
        commit('SET_ID', response.userinfo[0].data.navcode);
        localStorage.setItem("last_name", response.userinfo[0].data.last_name);
        localStorage.setItem("navcode", response.userinfo[0].data.navcode);
        localStorage.setItem("usertype", response.user.user_type)
        localStorage.setItem("country_code", response.userinfo[0].data.country_code)
      } else {
        localStorage.setItem("last_name", response.user.name);
        localStorage.setItem("navcode", "");
        localStorage.setItem("usertype", response.user.user_type)
      }

      commit('SET_NAME', localStorage.getItem("last_name") );
      commit('SET_SUBNAME', "");

      commit('SET_PERMISSIONS',response.allPermission)


      // id this user is a factoru user redirec to the factory login ui
      const isUserIsaWorker = userInfo.roles.find(x => x.name == '#INTRANET::FRONT_factory worker')
      // console.log(isUserIsaWorker)
      if(isUserIsaWorker){
        // console.log("happended")
        //router.push('/operation/rmafactory/login')
        pushTo = '/operation/rmafactory/login'
      }
      // router.push('/home') 
      router.push(pushTo)
    }).catch(error => {
      console.log(error);
    });
  },

  authCallback({ commit }, userInfo){
    const { code, state, query } = userInfo;
    //this is an axios instance which is already a promise
    //note that if not using axios this should be reword a bit
    authCallback({ code, state, query }).then(response => {  
      let pushTo = '/dashboard';
      if (response.user.user_type !== "shop"){
        pushTo = '/operation/dashboard';
      }
      storageUtils.setLoginToken(response.token)
      const userInfo = {
        allPermission : response.allPermission,
        roles : response.roles,
        user: response.user,
        userinfo : response.userinfo
      }

      storageUtils.setLoginUser(JSON.stringify(userInfo))

      // console.log("auth.js --------------")
      // console.log(response.user)
      if (response.userinfo && response.userinfo.length > 0 && response.userinfo[0].data) {
        commit('SET_ID', response.userinfo[0].data.navcode);
        localStorage.setItem("last_name", response.userinfo[0].data.last_name);
        localStorage.setItem("navcode", response.userinfo[0].data.navcode);
        localStorage.setItem("usertype", response.user.user_type)
        localStorage.setItem("country_code", response.userinfo[0].data.country_code)
      } else {
        localStorage.setItem("last_name", response.user.name);
        localStorage.setItem("navcode", "");
        localStorage.setItem("usertype", response.user.user_type)
      }

      commit('SET_NAME', localStorage.getItem("last_name") );
      commit('SET_SUBNAME', "");
      
      commit('SET_PERMISSIONS',response.allPermission)
      


      // id this user is a factoru user redirec to the factory login ui
      const isUserIsaWorker = userInfo.roles.find(x => x.name == '#INTRANET::FRONT_factory worker')
      // console.log(isUserIsaWorker)
      if(isUserIsaWorker){
        // console.log("happended")
        //router.push('/operation/rmafactory/login')
        pushTo = '/operation/rmafactory/login'
      }
      // router.push('/home') 
      router.push(pushTo)
    }).catch(error => {
      router.push('/dashboard');
      console.log(error);
    });
  },

  // user logout
  logout({ commit }) {
    logout({}).then(response => {
      storageUtils.removeAllCredential();
      commit('SET_PERMISSIONS','');
      const { isWorkerLogged } = useLockRepositories();
      isWorkerLogged.value = false;
      window.location.href = response.redirect_uri;
    });
  },

  logoutOld({ commit }) {

    storageUtils.removeAllCredential();

    commit('SET_PERMISSIONS','')
    // //todo to be removed
    const { isWorkerLogged } = useLockRepositories()
    // // rename reset function
    const { reset: resetRMA } = useRmaRepositories()
    const { reset: resetTracking } = useTrackingRmaRepositories()
    isWorkerLogged.value = false
    resetRMA();
    resetTracking();
    router.push('/login?backups=true'); 

  },

  setName({ commit }, newName) {
    commit('SET_NAME', newName );
  },
  setSubname({ commit }, newSubname) {
    commit('SET_SUBNAME', newSubname)
  }

};

export default {
  state,
  mutations,
  actions,
};
