import { ref, onMounted } from "vue";
import {
  fetchRmaReasons,
  fetchRmalist,
  fetchRmalistForOperation,
  fetchRmalistForOperationByPage,
  fetchRmaStatusList,
  fetchRmalistForOperationByStatus,
  fetchRmaByTrackingCode,
  storeRma,
  deliverType,
  UpdateStatus,
  printPackageLabel,
  printSingleLabel, 
  getAllRmaWorkers,
  createWorkerApi,
  updateRmaWorkerApi,
  deleteWorkerApi,
  getAllWorkersRoleApi,
  updateWorkersRoleApi,
  createWorkersRoleApi,
  deleteWorkersRoleApi,
  getWorkersPermissionListApi,
  fetchRMASentToWarehouse,
  generateExcelFile,
  fetchRMATransitToShop,
  fetchCreateCancelCloselist,
  postFactoryComment,
  postStoreComment,
  fetchComments,
  fetchDashboardStats,
  fetchDashboardCreateHistory,
  createSAVCheckApi,
  fetchRmaRepairFees,
  fetchRmaSavCheck,
  fetchWsProduct,
  fetchImportProductTypesense,
  fetchPackageStatus,
  fetchStorePackage,
  fetchFactoryPackage,
  fetchPackageprice,
  fetchGetSignature,
  fetchGetCarrierList,
  fetchClearOrderStatus,
  fetchCanTransfer,
  fetchTranferFactory,
  fetchNeedTranfer,
  fetchTranferList,
  fetchTranferPackage,
  fetchTranferClear,
  fetchRmaCountry,
  fetchRmaLanguage,
  fetchRejectReason,
  fetchProductCert,
  postUploadGoldImage,
  fetchAllReasonsList,
  fetchSubReasonsList,
  fetchSubReason,
  postSubReason,
  postFakeProduct,
  fetchHasPuchaseRecord,
  fetchUpdateTrackingNumber,
  fetchAfterSalesList,
  fetchUpdateAfterSales,
  fetchBoutiqueCountryList,
  fetchShippingFeeList,
  fetchVerifyInvoice
} from "@/services/rmaRepositories";
import { GetCustomerRecord } from "@/services/clientRepositories";
import { getCaptcha } from "@/services/auth";
import { formatDate, formatName } from '@/utils/utils';

const rmas = ref([]);
const rma_reasons = ref([]);
const rma_tracking = ref([]);
const rma_trackingNumber = ref("");
const rma_workerlist = ref([]);
const rma_worker_role_list = ref([]);
const rma_worker_permission_list = ref([]);// available permision list
const delivery_list = ref([]);// available permision list

export default function useRmaRepositories() {

  const reset = () => {
    rmas.value = []
    rma_reasons.value = []
    rma_tracking.value = []
    rma_trackingNumber.value = ""
    rma_workerlist.value = []
    rma_worker_role_list.value = []
    rma_worker_permission_list.value = []
  }

  const getDashboardStats = async () => {
    return await fetchDashboardStats().then((result) => {
      return result.data;
    });
  };

  const getDashboardCreateHistory = async () => {
    return await fetchDashboardCreateHistory().then((result) => {
      result.data.forEach(v => {
        v.CreateWeek = parseInt(v.CreateWeek) + 1
        v.week = `${v.CreateYear}-Week-${v.CreateWeek}`
      })
      return result.data;
    });
  };

  const getRma = async (param) => {
    return await fetchRmalist(param).then((result) => {
      if (result.data && result.data.length > 0) {
        result.data.map((data) => {
          data.key = data.id;
          data.fullname = formatName(data.firstname, data.lastname);
          data.email = data.email != null && data.email != 'null' ? data.email : '';
          data.fullphone = data.phone;
          data.received_date = formatDate(data.receive_item_date)
          data.img =
            "https://imgcdn.apm-monaco.cn/products-images/" +
            data.product_sku +
            ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76";
          return data;
        });
        result.data.sort(function (a, b) {
          return b.id - a.id;
        });
      }
      return result;
    });
  };


  const getAllRma = async () => {
    if (localStorage.getItem("usertype")!== "shop"){
      return []
    }    
    rmas.value = await fetchRmalist().then((result) => {
      if (result.data && result.data.length > 0) {
        result.data.map((data) => {
          data.key = data.id;
          data.fullname = formatName(data.firstname, data.lastname);
          data.email = data.email != null && data.email != 'null' ? data.email : '';
          data.fullphone = data.phone;
          data.received_date = formatDate(data.receive_item_date)
          data.img =
            "https://imgcdn.apm-monaco.cn/products-images/" +
            data.product_sku +
            ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76";
          return data;
        });
        result.data.sort(function (a, b) {
          return b.id - a.id;
        });
      }
      return result;
    });
  };
  
  const getRmaByStoreIDStatus = async (param) => {
    return await fetchRmalist(param).then((result) => {
      if (result.data === null) {
        result.data = []
      }
      result.data.map((data) => {
        data.key = data.id;
        data.fullname = formatName(data.firstname, data.lastname);
        data.email = data.email != null && data.email != 'null' ? data.email : '';
        data.fullphone = data.phone;
        data.received_date = formatDate(data.receive_item_date)
        data.img =
          "https://imgcdn.apm-monaco.cn/products-images/" +
          data.product_sku +
          ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76";
        return data;
      });
      result.data.sort(function(a, b) {
        return b.id - a.id;
      });
      return result;
    });
  };

  const getRmaForOperation = async (param) => {
    // console.log("before fetchRmalistForOperation")
    return await fetchRmalistForOperation(param).then((result) => {
      // console.log("fetchRmalistForOperation successful")
      if (result.data !== null) {
        result.data.map((data) => {
          data.key = data.id;
          data.fullname = formatName(data.firstname, data.lastname);
          data.email = data.email != null && data.email != 'null' ? data.email : '';
          data.fullphone = data.phone;
          data.received_date = formatDate(data.receive_item_date)
          data.img =
            "https://imgcdn.apm-monaco.cn/products-images/" +
            data.product_sku +
            ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76";
          return data;
        });
        result.data.sort(function(a, b) {
          return b.id - a.id;
        });
      } else {
        result.data = []
      }
      return result;
    });
  };

  const getRmaForOperationByPage = async (param) => {
    return await fetchRmalistForOperationByPage(param).then((result) => {
      if (result.data !== null) {
        result.data.map((data) => {
          data.key = data.id;
          data.fullname = formatName(data.firstname, data.lastname);
          data.email = data.email != null && data.email != 'null' ? data.email : '';
          data.fullphone = data.phone;
          data.received_date = formatDate(data.receive_item_date)
          data.img =
            "https://imgcdn.apm-monaco.cn/products-images/" +
            data.product_sku +
            ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76";
          return data;
        });
      } else {
        result.data = []
      }
      return result;
    });
  };

  const getClientRmaDataByPage = async (parms) => {
    return await GetCustomerRecord(parms).then((result) => {
      if (result.data !== null) {
        result.data.map((data) => {
          data.key = data.id;
          data.fullname = formatName(data.firstname, data.lastname);
          data.email = data.email != null && data.email != 'null' ? data.email : '';
          data.fullphone = data.phone;
          data.received_date = formatDate(data.receive_item_date)
          data.img = "https://imgcdn.apm-monaco.cn/products-images/" + data.product_sku + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76";
          return data;
        });
      } else {
        result.data = []
      }
      return result;
    });
  }

  const getRmaStatusList = async () => {
    return await fetchRmaStatusList().then((result) => {
      result.data.forEach(v => {
        v.value = JSON.stringify({
          rma_status_id: v.ID,
          label: v.Name
        })
        v.text = v.Name
      });
      return result.data;
    });
  };

  const getRmaForOperationByStatus = async (param) => {
    return await fetchRmalistForOperationByStatus(param).then((result) => {
      if (result.data !== null) {
        result.data.map((data) => {
          data.key = data.id;
          data.fullname = formatName(data.firstname, data.lastname);
          data.email = data.email != null && data.email != 'null' ? data.email : '';
          data.fullphone = data.phone;
          data.received_date = formatDate(data.receive_item_date)
          data.img =
            "https://imgcdn.apm-monaco.cn/products-images/" +
            data.product_sku +
            ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76";
          return data;
        });
        result.data.sort(function(a, b) {
          return b.id - a.id;
        });
      } else {
        result.data = []
      }
      return result;
    });
  };

  const getRMASentToWarehoure = async () => {
    return fetchRMASentToWarehouse().then((result) => {
      return result.data;
    });
  };

  const getRMAInTransitToShop = async () => {
    return fetchRMATransitToShop().then((result) => {
      return result.data;
    });
  };
   
  const getRmaByTrackingCode = async (tracking_code, operation_id) => {
    const raw = {
      tracking_number: tracking_code,
    };
    // console.log("################")
    // console.log(operation_id)
    rma_tracking.value = await fetchRmaByTrackingCode(JSON.stringify(raw)).then(
      (result) => {
        // 0 unScanned - RED
        // 1 Scanned - GREEN
        // 2 Already Validated - 
        if (result.data === null) return result;
        result.data.map((data) => {
          data.key = data.id;
          if (data.rma_status_id === operation_id) {
            data.is_scanned = true;
            data.is_saved = true;
          } else if (data.rma_status_id < operation_id) {
            data.is_scanned = false;
            data.is_saved = false;
          } else {
            data.is_scanned = true;
            data.is_saved = true;
          }
          data.img =
            "https://imgcdn.apm-monaco.cn/products-images/" +
            data.product_sku +
            ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76";
          if (data.rma_factory_sav_reason.length > 0) {
            data.FactoryReasons = data.rma_factory_sav_reason.map(item => {
              return parseInt(item.ID)
            })
          } else {
            data.FactoryReasons = data.rma_item_to_reason.map(item => {
              return parseInt(item.rma_item_reason_id)
            })
          }
          // 工厂接收步骤时使用
          if (operation_id == 3) {
            data.QualityInvestigateOptions = [
              {
                label: 'Production Issue',
                value: 'Production Issue'
              },
              {
                label: 'Design Issue',
                value: 'Design Issue'
              },
              {
                label: 'Components Issue',
                value: 'Components Issue'
              },
              {
                label: 'Customers Issue',
                value: 'Customers Issue'
              },
            ]
            data.FactorySubReasons = []
            data.subReasonList = []
            data.OriginalProduceFactory = 'SAV'
            data.QualityInvestigate = data.QualityInvestigateOptions[0].value
            // 如果已过保修期,默认Expire warranty,并不能修改
            if (!data.is_under_warranty) {
              data.QualityInvestigateOptions.unshift({
                label: 'Expire Warranty',
                value: 'Expire warranty'
              })
              data.QualityInvestigate = 'Expire warranty'
              data.QualityInvestigateDisabled = true
            }
            // 如果维修次数>2,则默认选择SAV Repair Issue,并不能修改
            if (data.repair_history.total_180 > 2) {
              data.QualityInvestigateOptions.unshift({
                label: 'SAV Repair Issue',
                value: 'SAV Repair Issue'
              })
              data.QualityInvestigate = 'SAV Repair Issue'
              data.QualityInvestigateDisabled = true
            }
          }
          return data;
        });
        rma_trackingNumber.value = tracking_code;
        result.data.sort(function(a, b) {
          return Number(a.is_scanned) - Number(b.is_scanned)
        });
        return result;
      }
    );
    return rma_tracking
  };

  const postRmaSavCheck = async (data) => {
    return fetchRmaSavCheck(data).then((result) => {
      return result;
    });
  };

  const getRmaReasons = async () => {
    rma_reasons.value = await fetchRmaReasons().then((result) => {
      result.data.map((data) => {
        data.checked = false;
        return data;
      });
      result.data = result.data.filter(function (ele) {
        return ele.IsActive == true
      })
      return result;
    });
  };

  const getRmaRepairFees = async () => {
    return fetchRmaRepairFees().then((result) => {
      return result;
    });
  };

  const createRma = async (rma) => {
    // console.log('-----------------------')
    // console.log(rma)
    const result = await storeRma(rma);
    return result;
  };

  const deliverTypeFn = async (rma) => {
    // console.log('-----------------------')
    // console.log(rma)
    const result = await deliverType(rma);
    return result;
  };

  // const updateRmaStatus = async (ID, rmaStatusID, trackingCode, wh_carrier_representation) => {
  const updateRmaStatus = async (post_data) => {
    // const raw = {
    //   id: ID,
    //   rma_status_id: rmaStatusID,
    // };

    // if (trackingCode!=="") {
    //   raw.retail_carrier_code = trackingCode;
    // }

    // if (wh_carrier_representation) {
    //   raw.wh_carrier_representation = wh_carrier_representation;
    // }

    // console.log(raw, "raw")
    
    let res = await UpdateStatus(JSON.stringify(post_data));
    if(res.success){
      return {
        id: post_data.id,
        rma_status_id: post_data.rma_status_id,
        message: "update success",
        success: res.success
        // id: ID,
        // rma_status_id: rmaStatusID,
        // message: "update success",
      };
    }else{
      return {
        id: post_data.id,
        rma_status_id: post_data.rma_status_id,
        message: res.message,
        success: res.success
        // id: ID,
        // rma_status_id: rmaStatusID,
        // message: "update success",
      };
    }
  };

  const printPackageList = async (IDarray, trackingCode, rma_status_id) => {
    const raw = {
      rma_id: IDarray,
    };

    if (trackingCode) {
      raw.rma_tracking = trackingCode;
    }

    if (rma_status_id) {
      raw.rma_status_id = rma_status_id;
    }

    const result = await printPackageLabel(JSON.stringify(raw));

    return {
      rma_id: IDarray,
      rma_tracking: trackingCode,
      message: "Package List Prepared",
      data: result,
    };
  };

  const printItemLabel = async (ID, trackingCode) => {
    const raw = {
      rma_id: ID,
    };

    if (trackingCode) {
      raw.rma_tracking = trackingCode;
    }

    const result = await printSingleLabel(JSON.stringify(raw));

    return {
      rma_id: ID,
      message: "Item Label Prepared",
      data: result,
    };
  };

  const generateExcel = async (excelType,rmaItems) => {
    const result = await generateExcelFile({rmaItem:rmaItems,excelType:excelType});
    // console.log(result)

    return result
  };

  const getCreateCancelCloselist = async () => {
    return fetchCreateCancelCloselist().then((result) => {
      return result
    })
  }

  const updateOperationComment = async (ID, Comment, Email) => {
    const raw = {
      id: ID,
      comment: Comment,
      email: Email
    };

    try {
      await postFactoryComment(JSON.stringify(raw));
    } catch (error) {
      console.log(error, "updateOperationComment")
    }
    return {
      id: ID,
      comment: Comment,
      email: Email,
      message: "update success",
    };
  };

  const updateStoreComment = async (ID, Comment, Email) => {
    const raw = {
      id: ID,
      comment: Comment
    };

    try {
      await postStoreComment(JSON.stringify(raw));
    } catch (error) {
      console.log(error, "updateStoreComment")
    }
    return {
      id: ID,
      comment: Comment,
      message: "update success",
    };
  };

  const getComments = async (ID, Comment_type) => {
    const param = `id=${ID}&comment_type=${Comment_type}`
    const result = await fetchComments(param);
    if (result.success) {
      result.comments.reverse().forEach((v, i) => {
        v.index = i + 1
      })
      return result.comments.reverse()
    } else {
      return []
    }
  };

  const listRmaWorkers = async()=> {
    rma_workerlist.value = await getAllRmaWorkers().then((result) => {
      if (result.data && result.data.length > 0) {
        result.data.map((data) => {
          data.id = data.ID
          data.workerid = data.WorkerID
          data.name = data.WorkerName
          data.barcode = data.Barcode,
          data.isActive = data.IsActive,
          data.department = data.Department,
          data.role = data.Roleobj.Name
          data.roleobj = data.Roleobj
          return data;
        });
        result.data.sort(function (a, b) {
          return b.id - a.id;
        });
      }
      return result;
    });
  }

  const createWorker = async(data) => {
    const x = {};
    x.WorkerID = data.workerID;
    x.WorkerName = data.name
    x.Barcode =  data.barcode.toString()
    x.Department = data.department
    x.Role = data.role
    x.IsActive = data.isActive

    const result = await createWorkerApi(x);
    listRmaWorkers()
    return result
  }

  const updateWorker = async(data) => {
    // console.log("mmmm",data)
    const x = {};
    x.ID = data.id;
    x.WorkerID = data.workerID;
    x.WorkerName = data.name
    x.Barcode =  data.barcode.toString()
    x.Department = data.department
    x.Role = data.role
    x.IsActive = data.isActive
    const result = await updateRmaWorkerApi(x);
    listRmaWorkers()
    return result
  }

  const deleteWorker = async(data) => {
    const x = {};
    x.ID = data.id;
    x.WorkerID = data.workerID;
    x.WorkerName = data.name
    x.Barcode =  data.Barcode
    x.Department = data.department
    x.Role = data.role
    const result = await deleteWorkerApi(data);
    listRmaWorkers()
    return result
  }
 
  const getAllWorkersRole = async()=> {
    const result = await getAllWorkersRoleApi();
    result.data.map((data) => {
      data.Selected = false;
      return data;
    });
    rma_worker_role_list.value  = result;
    return result;
  }
  const updateWorkersRole = async(data)=> {
    const result = await updateWorkersRoleApi({"ID":data.ID,"Name":data.Name,"Permission":data.Role});
    return result;
  }
  const createWorkersRole = async(data)=> {
    const result = await createWorkersRoleApi({"Key":data.Key,"Name":data.Name});
    return result;
  }
  const deleteWorkersRole = async(data)=> {
    const result = await deleteWorkersRoleApi(data);
    return result;
  }

  const getWorkersPermissionList = async()=> {
    const result  = await  getWorkersPermissionListApi();
    rma_worker_permission_list.value = result.data
    return result.data;
  }

  const createSAVCheck = async(data) => {
    const result = await createSAVCheckApi(data);
    return result;
  }

  const getWsProduct = async(sku, size) => {
    const param = `sku=${sku}&size=${size}`
    const result = await fetchWsProduct(param);
    return result;
    // return result.data.Metal_Color == 'W' || result.data.Metal_Color == 'S';
  }

  const importProductTypesense = async() => {
    const result = await fetchImportProductTypesense();
    return result
  }

  const getPackageStatus = async(tracking_no, order_type) => {
    const param = `tracking_no=${tracking_no}&order_type=${order_type}`
    const result = await fetchPackageStatus(param);
    return result.can_send;
  }

  const postSendPackage = async(tracking_no, type) => {
    const data = {
      tracking_no
    }
    if (type == 0) {
      return await fetchStorePackage(data);
    } else if (type == 1) {
      return await fetchFactoryPackage(data);
    }
  }

  const getPackageprice = async(tracking_no, type) => {
    const param = `tracking_no=${tracking_no}&order_type=${type}`
    const result =  await fetchPackageprice(param);
    if (result.success && result.data && result.data.length > 0) {
      result.data.forEach(v => {
        v.quantity = 1
      })
    }
    return result;
  }

  const fetchGetSignatures = async(data) => {
    const result = await fetchGetSignature(data);
    return result;
  }

  const getCarrierList = async() => {
    const result = await fetchGetCarrierList();
    if (delivery_list.value.length == 0) delivery_list.value = result.data;
    return result;
  }

  const getClearOrderStatus = async(rma_id, start_date, end_date, limit, valid_date_of_pruchase) => {
    const param = `rma_id=${rma_id}&start_date=${start_date}&end_date=${end_date}&limit=${limit}&valid_date_of_pruchase=${valid_date_of_pruchase}`
    const result = await fetchClearOrderStatus(param);
    return result;
  }

  const postCanTransfer = async(data) => {
    const result = await fetchCanTransfer(data);
    return result;
  }

  const postTranferFactory = async(data) => {
    const result = await fetchTranferFactory(data);
    return result;
  }

  const postNeedTranfer = async(data) => {
    const result = await fetchNeedTranfer(data);
    return result;
  }

  const getTranferList = async() => {
    const result = await fetchTranferList();
    if (result.data !== null) {
      result.data.map((data) => {
        data.key = data.id;
        data.fullname = formatName(data.firstname, data.lastname);
        data.email = data.email != null && data.email != 'null' ? data.email : '';
        data.fullphone = data.phone;
        data.received_date = formatDate(data.receive_item_date)
        data.img = "https://imgcdn.apm-monaco.cn/products-images/" + data.product_sku + ".jpg?x-oss-process=image/resize,w_300,h_300/quality,q_76";
        data.is_scanned = true
        return data;
      });
      result.data.sort(function(a, b) {
        return b.id - a.id;
      });
    } else {
      result.data = []
    }
    return result;
  }

  const getTranferPackage = async() => {
    const result = await fetchTranferPackage();
    return result;
  }

  const getTranferClear = async() => {
    const result = await fetchTranferClear();
    return result;
  }

  const getRmaCountry = async() => {
    const result = await fetchRmaCountry();
    return result;
  }

  const getRmaLanguage = async() => {
    const result = await fetchRmaLanguage();
    return result;
  }

  const getRejectReasonList = async (user_type) => {
    const result = await fetchRejectReason(user_type);
    return result.data;
  }

  const getCaptchaCode = async () => {
    const result = await getCaptcha();
    return result;
  }

  // 获取黄金商品序列号信息
  const getProductCert = async (cert_no) => {
    const result = await fetchProductCert(cert_no);
    return result;
  }

  // 上传黄金称重图片
  const uploadGoldImage = async (rma) => {
    const result = await postUploadGoldImage(rma);
    return result;
  };

  // 获取原因列表
  const getAllReasonsList = async () => {
    const result = await fetchAllReasonsList();
    return result;
  };

  // 根据主原因获取子原因列表
  const getSubReasonsList = async (reason_id) => {
    const result = await fetchSubReasonsList(reason_id);
    return result;
  };

  // 获取子原因
  const getSubReasons = async (reason_id) => {
    const result = await fetchSubReason(reason_id);
    return result;
  };

  // 添加或修改子原因
  const updateSubReason = async (reason_id) => {
    const result = await postSubReason(reason_id);
    return result;
  };

  // 上传假货信息
  const uploadFakeProduct = async (fakesInfo) => {
    const result = await postFakeProduct(fakesInfo);
    return result;
  };
  // 查询购买记录
  const getHasPuchaseRecord = async (rma_item_id) => {
    const result = await fetchHasPuchaseRecord(rma_item_id);
    if (result.success && result.status == 200) {
      return result.data.status == 1;
    }
    return false
  };

  // 更新快递单号
  const postUpdateTrackingNumber = async (rma_item_id) => {
    const result = await fetchUpdateTrackingNumber(rma_item_id);
    return result;
  };

  // 查询售后状态列表
  const getAfterSalesList = async (rma_item_id) => {
    const result = await fetchAfterSalesList(rma_item_id);
    return result;
  };
  // 更新售后状态
  const postUpdateAfterSales = async (data) => {
    const result = await fetchUpdateAfterSales(data);
    return result;
  };

  // 查询Boutique国家列表
  const getBoutiqueCountryList = async () => {
    const result = await fetchBoutiqueCountryList();
    return result;
  };

  // 查询国际运费列表
  const getShippingFeeList = async () => {
    const result = await fetchShippingFeeList();
    return result;
  };

  // 校验发票号
  const getVerifyInvoice = async (data) => {
    console.log(data)
    const result = await fetchVerifyInvoice(data);
    return result;
  };

  //To be tested
  // onMounted(getAllRma);

  // onMounted(listRmaWorkers);
  // onMounted(getAllWorkersRole);
  //onMounted(getWorkersPermissionList)

  return {
    rmas,
    rma_tracking,
    rma_trackingNumber,
    rma_reasons,
    rma_workerlist,
    rma_worker_role_list,
    rma_worker_permission_list,
    delivery_list,
    reset,
    getRma,
    getAllRma,
    getRmaReasons,
    getRmaByTrackingCode,
    updateRmaStatus,
    printPackageList,
    getRMASentToWarehoure,
    printItemLabel,
    createRma,
    deliverTypeFn,
    getRmaByStoreIDStatus,
    getRmaForOperation,
    getRmaForOperationByPage,
    getRmaStatusList,
    getRmaForOperationByStatus,
    generateExcel,
    getRMAInTransitToShop,
    getCreateCancelCloselist,
    listRmaWorkers,
    createWorker,
    updateWorker,
    deleteWorker,
    getAllWorkersRole,
    updateWorkersRole,
    createWorkersRole,
    deleteWorkersRole,
    getWorkersPermissionList,
    updateOperationComment,
    updateStoreComment,
    getComments,
    getDashboardStats,
    getDashboardCreateHistory,
    createSAVCheck,
    getRmaRepairFees,
    postRmaSavCheck,
    getWsProduct,
    importProductTypesense,
    getPackageStatus,
    postSendPackage,
    getPackageprice,
    getClientRmaDataByPage,
    fetchGetSignatures,
    getCarrierList,
    getClearOrderStatus,
    postCanTransfer,
    postTranferFactory,
    postNeedTranfer,
    getTranferList,
    getTranferPackage,
    getTranferClear,
    getRmaCountry,
    getRmaLanguage,
    getRejectReasonList,
    getCaptchaCode,
    getProductCert,
    uploadGoldImage,
    getAllReasonsList,
    getSubReasonsList,
    getSubReasons,
    updateSubReason,
    uploadFakeProduct,
    getHasPuchaseRecord,
    postUpdateTrackingNumber,
    getAfterSalesList,
    postUpdateAfterSales,
    getBoutiqueCountryList,
    getShippingFeeList,
    getVerifyInvoice
  };
}
