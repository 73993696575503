<template>
  <div class="content-wrapper" v-if="isShowLoginPage">
    <div class="login-content block-center">
      <div class="login" >
        <div v-if="isNoToken">
          <a-form 
            @submit="login" 
            :rules="rules"  
            :model="formData" 
          >
            <a-form-item name="username">
              <a-input
                style="width: 280px;"
                size="large"
                type="text"
                v-model:value="formData.username" 
                :placeholder="$t('login.email-placeholder')"
              >
                <template #prefix>
                  <UserOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />  
                </template>
              </a-input>
            </a-form-item>
            <a-form-item name="password">
              <a-input
                style="width: 280px;"
                size="large"
                type="password"
                v-model:value="formData.password"
                :placeholder="$t('login.password-placeholder')"
              >
                <template #prefix>
                  <LockOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item name="captcha" v-if="captchaData.captcha.CaptchaId">
              <a-input
                style="width: 180px;"
                size="large"
                v-model:value="formData.captcha"
                :placeholder="$t('login.captcha-placeholder')"
              >
                <template #prefix>
                  <SafetyCertificateOutlined :style="{ color: 'rgba(0,0,0,.25)' }" />
                </template>
              </a-input>
              <a-button class="captcha-image" @click="updateCaptchaCode" type="text" :style="`background: #fff url(${captchaData.captcha.PicPath}) center / 100% 100% no-repeat;`" size="large">
                <template>
                  <div style="visibility: hidden;">1</div>
                </template>
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button
                type="primary"
                html-type="submit"
                :disabled="captchaData.loginBtnDisabled"
                :loading="captchaData.loginBtnDisabled"
              >
                {{ captchaData.loginBtnDisabled ? $t('login.waiting') : $t('login.logIn')  }}
              </a-button>
            </a-form-item>
          </a-form>
          <LangSwitch/>
        </div>
        <template v-else>
          <a-space>
            <!-- <a-button @click="logout()"> Logout </a-button> -->
            <a-button @click="$router.go(-1)"> Back </a-button>
          </a-space>
        </template>
        
        <br/><br/>

      </div>
    </div>
    <div class="content-footer">
      <p v-text="dateTime.time" />
      <p v-text="dateTime.date" />
      <a href="http://beian.miit.gov.cn" target="_blank" style="color: white;">
        <div style="font-size: small;">@{{new Date().getFullYear()}} 柏丽德珠宝(广州)有限公司 版权所有-粤ICP备19010787号</div>
      </a>
    </div>
  </div>
</template>

<script>
  import storageUtils from '@/utils/storage';
  import {ref, computed, reactive, onUnmounted, onMounted} from 'vue'
  import moment from 'moment' 
  import router from '@/router'
  import LangSelectMenu from '@/components/LangSelectMenu/LangSelectMenu'
  import { useI18n } from 'vue-i18n'
  import { UserOutlined, LockOutlined, SafetyCertificateOutlined } from '@ant-design/icons-vue';
  import { useStore } from 'vuex'
  import { notification } from 'ant-design-vue';
  import LangSwitch from '@/components/LangSwitch.vue';
  import useRmaRepositories from '@/composables/useRmaRepositories'
  export default ({
    components: {
      LangSwitch, 
      UserOutlined,
      LockOutlined,
      SafetyCertificateOutlined
    },
    setup() {
      const { t ,locale } = useI18n({ useScope: 'global' })
      const store = useStore();
      const { getCaptchaCode } = useRmaRepositories()
      const formData= reactive({
        username: '',
        password: '',
        is_captcha: 1,
        captcha_id: '',
        captcha: ''
      });
      const token = ref('')
      const captchaData = reactive({
        captchaTime: 0,
        intervalSeconds: 10000,
        initStatus: false,
        captcha: {},
        loginBtnDisabled: false
      })
      const dateTime = reactive({
        time:  moment().format('h:mm:ss A'),
        date:  moment().format('dddd, MMMM DD, YYYY')
      })
      // store.dispatch('auth/login',formData)
      const url = process.env.VUE_APP_RMA_MS_API_URL;
    

      //Todo create a validator utile in composable and import
      const validateName = async (rule,value,callback) => {
        // console.log(value)
        if (value == '') {
          return Promise.reject(t('login.pleaseEnterTheEmail'));
        } else {
          return Promise.resolve();
        }
      };

      const rules = {
        username:[{ validator: validateName, trigger: 'change' }],
        password: [{ required: true, message: t('login.pleaseEnterThePassword'), trigger: 'change' }],
        captcha: [{ required: true, message: t('login.pleaseEnterTheCaptcha'), trigger: 'blur' }]
      }

      const isShowLoginPage = ref(false);

      const handleUsernameOrEmail = (rule, value, callback) =>{
        const { state } = this
        const regex = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
        if (regex.test(value)) {
          state.loginType = 0
        } else {
          state.loginType = 1
        }
        callback()
      }
      const login = () => {
        if (formData.username == '') return notification.error({
            message: t('route.error'),
            description: t('login.pleaseEnterTheEmail')
          })
        if (formData.password == '') return notification.error({
            message: t('route.error'),
            description: t('login.pleaseEnterThePassword')
          })
        if (formData.captcha == '') return notification.error({
            message: t('route.error'),
            description: t('login.pleaseEnterTheCaptcha')
          })
        if (Number(storageUtils.getLoginTimes()) >= 5) {
          storageUtils.setLoginBtnDisabledTime(new Date().getTime() + 60000);
          checkStopLogining();
          return;
        }
        const loginTimes = Number(storageUtils.getLoginTimes()) + 1
        storageUtils.setLoginTimes(loginTimes)
        store.dispatch('auth/loginOld', formData)
        getCaptcha()
      }

      const updateCurrentTime = () => {
        dateTime.time = moment().format('h:mm:ss A')
      }

      const updateCaptchaCode = () => {
        if (captchaData.initStatus) {
          captchaData.initStatus = false
          getCaptcha()
        } else if (captchaData.captcha.CaptchaId && (new Date().getTime() - captchaData.captchaTime <= captchaData.intervalSeconds)) {
          notification.error({
            message: t('route.error'),
            description: t('captcha.updateCaptchaLater')
          })
        } else {
          getCaptcha()
        }
      }

      const getCaptcha = async () => {
        const result = await getCaptchaCode()
        if (result.success) {
          captchaData.captcha = result
          formData.captcha_id = captchaData.captcha.CaptchaId
          formData.captcha = ''
          captchaData.captchaTime = new Date().getTime()
        } else {
          notification.error({
            message: t('route.error'),
            description: t('captcha.captchaError')
          })
        }
      }

      const checkStopLogining = () => {
        const commonFunction = () => {
          captchaData.loginBtnDisabled = false;
          storageUtils.removeLoginTimes();
          storageUtils.removeLoginBtnDisabledTime();
        };
        if (storageUtils.getLoginTimes() == null || storageUtils.getLoginTimes() == undefined) storageUtils.setLoginTimes(0);
        if (storageUtils.getLoginBtnDisabledTime() && storageUtils.getLoginBtnDisabledTime() > new Date().getTime()) {
          captchaData.loginBtnDisabled = true;
          notification.error({
            message: t('route.error'),
            description: t('login.tryToLoginTooMany')
          })
          const timeout = storageUtils.getLoginBtnDisabledTime() - new Date().getTime();
          setTimeout(() => {
            commonFunction();
          }, timeout);
        } else if (storageUtils.getLoginBtnDisabledTime() && storageUtils.getLoginBtnDisabledTime() <= new Date().getTime()) {
          commonFunction();
        } else {
          captchaData.loginBtnDisabled = false;
        }
      };

      onMounted(() => {
        let query = router.currentRoute.value.query;

        console.log('query>>>>>>', query, query.backups);

        if(query.code){
          store.dispatch('auth/authCallback',{code: query.code, state: query.query})
          storageUtils.setLoginType('loginNew')
        }else if(query.backups == 'true'){
          storageUtils.setLoginType('loginOld');
          isShowLoginPage.value = true;
          captchaData.initStatus = true
          getCaptcha();
          checkStopLogining();
          return false;
        }else{
          store.dispatch('auth/login',{})
          storageUtils.setLoginType('loginNew')
        }

        // if (query.code) {
        //   store.dispatch('auth/authCallback',{code: query.code, state: query.state, query: JSON.stringify(query)})
        //   storageUtils.setLoginType('loginNew');
        // } else if (query.loginType == 'SSO') {
        //   store.dispatch('auth/login',{query: JSON.stringify(query)})
        //   storageUtils.setLoginType('loginNew')
        // } else {
        //   storageUtils.setLoginType('loginOld');
        //   isShowLoginPage.value = true;
        //   captchaData.initStatus = true
        //   getCaptcha();
        //   checkStopLogining();
        //   return false;
        // }
      })

      onUnmounted(() => { 
        if ((localStorage.getItem("country_code") === "CHN")) { 
          locale.value = "zh" 
        } else {
          locale.value = "en" 
        }
        // console.log('unmounted!')
      })
      // const changeLang = () => {
      // locale.value = 'zh'
      // store.dispatch('app/setLanguage','zh') 
      // console.log("loginHere")
      // }
      /*
        setTimeout(() => {
          changeLang()
        }, 3000);
      */

      const isNoToken = computed(() => token.value == '' || token.value == 'undefined')
      
      setInterval(() => updateCurrentTime(), 1 * 1000)
    
      return {
        login,
        token,
        isNoToken,
        dateTime,
        handleUsernameOrEmail,
        rules,
        formData,
        t, 
        locale,
        LangSwitch,
        isShowLoginPage,
        captchaData,
        updateCaptchaCode
      }
    },
  })
</script>

<style lang="scss" scoped>
@import "@/assets/scss/auth/auth";
.ant-btn-primary[disabled]{
  color:#5d5d5d
}
.login-content {
  .captcha-image {
    width: 90px;
    margin-left: 10px;
    vertical-align: top;
  }
}
</style>



<!--#INTRANET::FRONT_view sidebar group rma operation,#INTRANET::FRONT_view (warehouse) receive from shop,#INTRANET::FRONT_view (warehouse) send to repair,#INTRANET::FRONT_view (warehouse) send to planting,#INTRANET::FRONT_view (warehouse) receive from repair,#INTRANET::FRONT_view (warehouse) send to shop -->