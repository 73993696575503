//Some basic utils functions to be put here.
import imageCompression from 'browser-image-compression';

export async function compressImage(img) {
  let file = img;
  const options = {
    maxSizeMB: .5,
    maxWidthOrHeight: 1920,
    useWebWorker: true
  }

  const reg = new RegExp(/\.(png|jpg|jpeg)$/);

  if (!reg.test(img.name)) {
    const newFileName = img.name.replace(/\..*$/, '.jpg');
    const newFile = new File([img], newFileName, { type: 'image/jpeg', lastModified: img.lastModified, lastModifiedDate: img.lastModifiedDate });
    file = newFile;
  }

  const compressedimg = await imageCompression(file, options);

  return compressedimg
}

export function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getUTCMonth() + 1),
    day = '' + d.getUTCDate(),
    year = d.getUTCFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}

export function calcIsWarranty(create_date) {
  const date_create = new Date(create_date)
  date_create.setHours(0);
  date_create.setMinutes(0);
  date_create.setSeconds(0);
  const data_warranty = date_create.setFullYear(date_create.getFullYear() + 2)
  const date_now = new Date().getTime()
  return date_now < data_warranty
}

export function formatDateWithTime(date) {
  var d = new Date(date),
    month = '' + (d.getUTCMonth() + 1),
    day = '' + d.getUTCDate(),
    year = d.getUTCFullYear(),
    hour = '' + d.getUTCHours(),
    minute = '' + d.getUTCMinutes();

  if (month.length < 2) {
    month = '0' + month;
  }

  if (day.length < 2) {
    day = '0' + day;
  }

  if (hour.length < 2) {
    hour = '0' + hour;
  }

  if (minute.length < 2) {
    minute = '0' + minute;
  }

  return [year, month, day].join('-') + ' ' + [hour, minute].join(':');
}

export function generateRandom(min, max, step = 10) {
  const randomNum = min + Math.random() * (max - min);
  return Math.round(randomNum / step) * step;
}

export function formatName(firstname, lastname) {
  const reg = new RegExp(/[\u4e00-\u9fa5]{0,}/g)
  if (firstname == null || firstname == 'null') firstname = ''
  if (lastname == null || lastname == 'null') lastname = ''
  if (reg.test(firstname) || reg.test(lastname)) {
    return lastname + `${lastname && firstname ? " " : ''}` + firstname
  }
  return firstname + `${firstname && lastname ? " " : ''}` + lastname
}