//global getter if needed for all state
const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,
  userId: state => state.auth.id,
  name: state => state.auth.name,
  subname: state => state.auth.subname,
  userPerm: state => state.auth.permissions,

};
export default getters;
