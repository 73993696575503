export default {
  route: {
    Dashboard: "Dashboard",
    operationDashboard: "Dashboard",
    rmadetails: "RMA Details",
    listRma: "List all RMA",
    clientSearch: "Client Search",
    CreateRma: "Request new RMA",
    PreparePackage: "Prepare Package",
    ReceivedPackage: "Receive Package",

    operationlistRma: 'List all RMA',

    RMAReadyToShip: "RMA ready to ship",
    RMAReadyToShip72: "RMA ready to ship (>72h)",
    RMAWaitingForCustomer: "RMA waiting for customer",
    RMAWaitingForCustomer1week: "RMA waiting for customer (+1 week)",
    RMASentToWarehouse: "RMA Sent To Warehouse",
    RMAIntransit: "RMA In transit",

    Week: "Week",
    Day: "Day",
    TrackingCount: "Tracking#",
    RMACount: "RMA#",

    Size: "Size",
    Amount: "Amount",
    Quantity: "Quantity",
    Image: "Image",
    RepairTime: 'Repair Times',
    viewBtn: 'View ',

    Create: "Create",
    Created: "Created",
    SubmissionSuccess:"Submission Success",
    ReturnToRMAList: "Return to RMA List", 
    CreatedBy: "Created By",
    CreatedOn: "Created On",
    By: "By",
    confirmCustomerPickup: "Confirm Customer Pick up",
    reject: "Reject",
    pleaseSelectAProduct: "Please Select a Product",
    pleaseSelectAnInvoice: "Please Select an Invoice",
    selectRepairReason: "Select Repair Reasons",

    InStore: "IN STORE",
    TransitToFactory: "TRANSIT TO FACTORY",
    InRepair:"IN REPAIR",
    TransitToStore:"TRANSIT TO STORE",
    WaitingForPickUp:"WAITING FOR PICKUP", 

    OrderDate: "Order Date",
    Date: "Date",
    CreateDate: "Create Date",
    RMA: "RMA",
    Tracking: "Tracking",
    ShippingDate: "Shipping Date",   

    Number: "Number",
    Name: "Name",
    Phone: "Phone",
    Status: "Status", 
    RMAStatus: "RMA Status",
    DeleteRMA: "Delete RMA",
    RMAInformation: "RMA Information",
    blankRMA: "Blank RMA",
    province: "Province",
    address: 'Address',
    postalCode: 'Postal Code',
    toCustomer: 'To Customer',
    DeliverToCustomer: 'Deliver to customer',
    mailingInformation: "Shipping Information",
    receiverName: 'Receiver Name',
    receiverPhone: 'Receiver Phone',
    isAgent: 'Receive on behalf of another',
    agent: 'Agent',

    Searching:"Searching",
    CreateRMAForm: "Create RMA Form",
    Completed: "Completed",

    SearchingWithName : "Searching with (Name / Email / Phone)",
    SearchingWithTheInvoiceNumber : "Searching with the Invoice number",

    FirstName: "First name",
    LastName: "Last name", 
    Email: "Email",
    Invoice: "Invoice",
    InvoiceNo: "Invoice No",

    Search: "Search",
    Uploads: "Uploads",
    Uploaded: "Uploaded",
    images: "images",
    UploadTotalWeight: "Upload Image",
    Warranty: "Warranty",
    noWarranty: "No Warranty",
    Expired: "Expired",
    UnderWarranty: "Under Warranty",
    WarrantyDate: "Warranty Date",
    ExtendedWarranty: "Extended Warranty",
    Reasons: "Reasons",
    ChooseFiles:"Choose Files",
    Select: "Select",

    NoMatchesFound: "No matches found",

    RenewDetails: 'Select Repair Reasons(For information only)',

    Contact:"Contact",
    Item:"Item",
    Attachment:"Attachment",

    unscanned:"Unscanned",
    scanned:"Scanned",

    Adding:"Adding",
    Pending:"Pending",
    ClearAll: "Clear All",
    PreparePackage:"Prepare Package",
    TrackingNumber:"Tracking Number",
    ScanYourRMAProduct:"Scan Your RMA Product",
    ScanYourTrackingNumber:"Scan Your Tracking Number",
    LatestProductScanned:"Latest Product Scanned",

    RedIsUnscannedProduct :"Red is unscanned product",
    GreenIsScannedProduct :"Green is scanned product",
    // operation
    Validate:"Validate",
    GenerateExcel:"Generate Excel",
    DownloadExcelTemplate:"Download Excel Template",

    RMAOperations: "RMA Operations",
    RepairOperations: "Repair Operations",
    RepairProcess: "Repair Process",
    PolishingProcess: "Polishing Process",
    PlatingProcess: "Plating Process",
    AssemblyProcess: "Assembly Process",
    QCFinal: "QC Final",
    QCProcess: "QC Process",
    Assembly: "Assembly",
    PenPlatingProcess: "Pen-Plating Process",
    SettingStone: "Setting Stone",
    SettingPearl: "Setting Pearl",
    Repaired: "Repaired",
    LogisticShipOut: "Logistic Ship Out",
    MCToCN: "MC to CN",
    SwitchWorker: "Switch Worker",
    Worker: "Worker", 
    StoreID: "Store ID",
    StoreName: "Store Name",

    WorkID: "Work ID",
    Barcode: "Barcode",
    Department: "Department",
    Role: "Role",
    IsActive: "Is Active",
    Action: "Action",
    Active: "Active",
    InActive: "In Active", 
    Generate: "Generate",
    WorkerRole: "Worker Role",

    WorkerDepartment: "Worker Department",
    WorkerName: "Worker Name",
    AddWorker: "Add Worker",
    CreateANewWorker: "Create A New Worker",
    EditWorker: "Edit Worker",
    CreateANewRole: "Create A New Role",
    EditRole: "Edit Role",

    ListWorkers: "Workers List", 
    WorkerRoles: "Worker Roles",

    introduction: "Introduction",
    documentation: "Documentation",
    guide: "Guide",
    permission: "Permission",
    pagePermission: "Page Permission",
    rolePermission: "Role Permission",
    directivePermission: "Directives",
    icons: "Icons",
    components: "Components",
    componentIndex: "Introduction",
    tinymce: "Tinymce",
    markdown: "Markdown",
    jsonEditor: "JSON Editor",
    dndList: "Dnd List",
    splitPane: "SplitPane",
    avatarUpload: "Avatar Upload",
    dropzone: "Dropzone",
    sticky: "Sticky",
    countTo: "CountTo",
    componentMixin: "Mixin",
    backToTop: "BackToTop",
    dragDialog: "Drag Dialog",
    dragSelect: "Drag Select",
    dragKanban: "Drag Kanban",
    charts: "Charts",
    keyboardChart: "Keyboard Chart",
    lineChart: "Line Chart",
    mixChart: "Mix Chart",
    example: "Example",
    nested: "Nested Routes",
    menu1: "Menu 1",
    "menu1-1": "Menu 1-1",
    "menu1-2": "Menu 1-2",
    "menu1-2-1": "Menu 1-2-1",
    "menu1-2-2": "Menu 1-2-2",
    "menu1-3": "Menu 1-3",
    menu2: "Menu 2",
    table: "Table",
    dynamicTable: "Dynamic Table",
    dragTable: "Drag Table",
    inlineEditTable: "Inline Edit",
    complexTable: "Complex Table",
    treeTable: "Tree Table",
    customTreeTable: "Custom TreeTable",
    tab: "Tab",
    form: "Form",
    createArticle: "Create Article",
    editArticle: "Edit Article",
    articleList: "Articles",
    errorPages: "Error Pages",
    page401: "401",
    page404: "404",
    errorLog: "Error Log",
    excel: "Excel",
    exportExcel: "Export Excel",
    selectExcel: "Export Selected",
    mergeHeader: "Merge Header",
    uploadExcel: "Upload Excel",
    zip: "Zip",
    pdf: "PDF",
    exportZip: "Export Zip",
    theme: "Theme",
    clipboardDemo: "Clipboard",
    i18n: "I18n",
    externalLink: "External Link",
    elementUi: "Element UI",
    administrator: "Administrator",
    users: "Users",
    userProfile: "User Profile",
    error: "Error",
    success: "Success",
    RMAHasBeenUpdated: "RMA has been Updated",
    RMAHasBeenCanceled: "RMA has been cancelled",
    confirmCanceled: "Are you sure you want to Delete?",

    factoryRemark: "Factory Remark",
    StoreRemark: "Store Remark",
    updateRemark: "Update Remark",
    confirmRemark: "Do you confirm to update the comment?",
    RMAFactoryRemarkUpdated: "RMA Factory has been updated",
    RMAStoreRemarkUpdated: "RMA Store has been updated",
    InputFactoryRemarkHere: "Input factory remark here",
    confirmPickup: "Do you confirm Customer have pickup the order?",
    rolename: "Role Name",
    roleid: "Role ID",
    addProduct: "Add Product",
    size: "Size",
    operationfactoryLogin: "Factory Login",
    save: 'Save',
    clear: 'Clear',
    submit: 'Submit',
    StoreReasons: 'Store Reasons',
    FactoryReasons: 'Factory Reasons',
    Import: "Import",
    TextLengthIsTooLong: 'The length of input text is limited to 120 words',
    ConfirmToSend: 'Send Package',
    Check: 'Check',
    pleaseCheckTrackNumber: 'Please check the Tracking Number first?',
    PurchaseDate: "Purchase Date",
    WarrantyType: "Under Warranty",
    RequestWaivedFee: 'Request Waived Fee',
    WaivedFee: 'Waived Fee',
    confirmWaivedFee: 'Please write the reason in the “Store Remark” field. You will receive the result by email in the upcoming days.',
    customerSign: 'Customer signature',
    systemManagement: "System Management",
    systemConfig: "System Config",
    orderClear: "Order Clear",
    subReasonManage: "SubReason Management",
    imageMandatory: 'Image are mandatory',
    goldWeightImageMandatory: 'Gold weight image are mandatory',
    more: 'More',
    yes: 'YES',
    no: 'NO',
    other: 'Other',
  },
  ExpressDelivery: {
    ExpressDeliveryCompany: 'Express Delivery',
    PleaseSelectExpressDeliveryCompany: 'Please Select Express Delivery Company',
    PleaseCheckRMAID: 'Please check whether the RMAID has filled it',
    PleaseCheckTrackingNumber: 'Please check whether the Tracking Number has filled it',
    PleaseCheckExpressDelivery: 'Please check whether the Express Company has filled it'
  },
  navbar: {
    logOut: "Log Out",
    dashboard: "Dashboard",
    github: "Github",
    theme: "Theme",
    size: "Global Size",
    profile: "Profile",
  },
  login: {
    title: "Login Form",
    logIn: "Log in",
    waiting: "Waiting",
    username: "Username",
    password: "Password",
    pleaseEnterTheEmail: "Please enter the email",
    pleaseEnterThePassword: "Please enter the password",
    pleaseEnterTheCaptcha: "Please enter the captcha",
    any: "any",
    thirdparty: "Or connect with",
    thirdpartyTips:
      "Can not be simulated on local, so please combine you own business simulation! ! !",
    email: "Email",
    "username-placeholder": "username",
    "email-placeholder": "email",
    "password-placeholder": "password",
    "captcha-placeholder": "captcha",
    tryToLoginTooMany: "Your login has been suspended. Please try again later"
  },
  documentation: {
    documentation: "Documentation",
    laravel: "Laravel",
    github: "Github Repository",
  },
  permission: {
    addRole: "New Role",
    submit: 'Submit',
    editPermission: "Edit Permission",
    roles: "Your roles",
    switchRoles: "Switch roles",
    tips:
      "In some cases it is not suitable to use v-role/v-permission, such as element Tab component or el-table-column and other asynchronous rendering dom cases which can only be achieved by manually setting the v-if with checkRole or/and checkPermission.",
    delete: "Delete",
    confirm: "Confirm",
    cancel: "Cancel",
  },
  components: {
    documentation: "Documentation",
    tinymceTips:
      "Rich text editor is a core part of management system, but at the same time is a place with lots of problems. In the process of selecting rich texts, I also walked a lot of detours. The common rich text editors in the market are basically used, and the finally chose Tinymce. See documentation for more detailed rich text editor comparisons and introductions.",
    dropzoneTips:
      "Because my business has special needs, and has to upload images to qiniu, so instead of a third party, I chose encapsulate it by myself. It is very simple, you can see the detail code in @/components/Dropzone.",
    stickyTips:
      "when the page is scrolled to the preset position will be sticky on the top.",
    backToTopTips1:
      "When the page is scrolled to the specified position, the Back to Top button appears in the lower right corner",
    backToTopTips2:
      "You can customize the style of the button, show / hide, height of appearance, height of the return. If you need a text prompt, you can use element-ui el-tooltip elements externally",
    imageUploadTips:
      "Since I was using only the vue@1 version, and it is not compatible with mockjs at the moment, I modified it myself, and if you are going to use it, it is better to use official version.",
  },
  table: {
    description: "Description",
    dynamicTips1: "Fixed header, sorted by header order",
    dynamicTips2: "Not fixed header, sorted by click order",
    dragTips1: "The default order",
    dragTips2: "The after dragging order",
    name: "Name",
    title: "Title",
    importance: "Imp",
    type: "Type",
    remark: "Remark",
    search: "Search",
    add: "Add",
    export: "Export",
    reviewer: "reviewer",
    id: "ID",
    date: "Date",
    author: "Author",
    readings: "Readings",
    status: "Status",
    actions: "Actions",
    edit: "Edit",
    publish: "Publish",
    draft: "Draft",
    delete: "Delete",
    cancel: "Cancel",
    confirm: "Confirm",
    keyword: "Keyword",
    role: "Role",
  },
  errorLog: {
    tips: "Please click the bug icon in the upper right corner",
    description:
      "Now the management system are basically the form of the spa, it enhances the user experience, but it also increases the possibility of page problems, a small negligence may lead to the entire page deadlock. Fortunately Vue provides a way to catch handling exceptions, where you can handle errors or report exceptions.",
    documentation: "Document introduction",
  },
  listRMA: {
    sku: "SKU",
    printLabel: "Print Label",
    printPackageList: "Print Package List",
    reset: "Reset",
    placeholder: "Please enter the file name(default excel-list)",
    printPackageListNow: "Click Here Print Package list Now",
    waivedFeeStatus_0: "NO",
    waivedFeeStatus_1: "Review",
    waivedFeeStatus_10: "Reject",
    waivedFeeStatus_11: "Approve",
    resetAll: "Reset All",
    rejectReasonTitle: "Do you confirm to reject this order?",
    pleaseSelectRejectReason: "Please Select Reject Reason"
  },
  productDetail: {
    orderNumber: "Order No",
    orderID: "Order ID",
    Date: "Order Date",
    RepairFee: "Repair Fee",
    InvoiceNumber: "Invoice Number",
    SequenceNo: "Sequence No",
    Grams: "(g)",
    goldProductInfo: "Gold Product Info",
    GoldWeight: "Gold Weight",
    CaratWeight: "Carat Weight",
    Carat: "(Carat)",
    totalWeight: "Total Weight",
    fakesInfo: 'Fake product Info',
    fakesInfoImage: 'Fake product Image',
    fakesInfoRemark: 'Fake Product Remark',
    fakesInfoUpload: 'Survey Upload',
    uploadFakesInfo: 'Upload',
    remark: 'Remark',
    pleaseUploadImage: 'Please upload image',
    pleaseInputRemark: 'Please input the remark',
    HavePuchaseRecord: 'Puchase Record',
    yesPuchaseRecord: 'Have Puchase Record',
    noPuchaseRecord: 'No Puchase Record',
    edit: 'Edit',
    editTrackingNumberModal: 'Edit Tracking Number',
    newTrackingNumber: 'Tracking Number',
    pleaseInputNewTrackingNumber: 'Please Input New Tracking Number',
    Delivery: 'Delivery',
    pleaseSelectDelivery: 'Please Select Delivery',
    ContactUsByPhone: 'Contact us by phone',
    ContactUsByWhatsapp: 'Contact us by WhatsApp',
    undo: 'Undo',
    pickupFollowup: 'Pickup Follow Up',
    contactLabel: 'Contact',
    pleaseSelectContactResult: 'Please Select Contact Result',
    "Phone: Contacted": 'Phone: Contacted',
    "Phone: No answer": 'Phone: No answer',
    "Whatsapp: Reminder Sent": 'Whatsapp: Reminder Sent',
    "Wechat: Reminder Sent": 'Wechat: Reminder Sent',
    "Email : Reminder Sent": 'Email : Reminder Sent',
    EmptyFollowUpList: 'You have not contact customer, please contact customer and record the result.',
    ShippingFee: 'Shipping Fee'
  },
  excel: {
    export: "Export",
    selectedExport: "Export Selected Items",
    placeholder: "Please enter the file name(default excel-list)",
  },
  zip: {
    export: "Export",
    placeholder: "Please enter the file name(default file)",
  },
  pdf: {
    tips:
      "Here we use window.print() to implement the feature of downloading pdf.",
  },
  theme: {
    change: "Change Theme",
    documentation: "Theme documentation",
    tips:
      "Tips: It is different from the theme-pick on the navbar is two different skinning methods, each with different application scenarios. Refer to the documentation for details.",
  },
  tagsView: {
    refresh: "Refresh",
    close: "Close",
    closeOthers: "Close Others",
    closeAll: "Close All",
  },
  settings: {
    title: "Page style setting",
    theme: "Theme Color",
    tagsView: "Open Tags-View",
    fixedHeader: "Fixed Header",
    sidebarLogo: "Sidebar Logo",
  },
  user: {
    role: "Role",
    password: "Password",
    confirmPassword: "Confirm password",
    name: "Name",
    email: "Email",
  },
  roles: {
    description: {
      admin:
        "Super Administrator. Have access and full permission to all pages.",
      manager:
        "Manager. Have access and permission to most of pages except permission page.",
      editor:
        "Editor. Have access to most of pages, full permission with articles and related resources.",
      user: "Normal user. Have access to some pages",
      visitor:
        "Visitor. Have access to static pages, should not have any writable permission",
    },
  },
  SAVCheck: {
    SAVCheckResult: "SAV Check Result",
    TrackingNumber:"Tracking Number",
    WrongReasonNumber:"Wrong Reason Count",
    noWarrantyNumber:"No Warranty Count",
    enterTrackingNumber: "Please enter a Tracking Number",
    enterWrongReasonNumber: "Please enter Wrong Reason Count",
    enterNoWarrantyNumber: "Please enter No Warranty Count",
    enterNumber: "Please enter a number",
    errorTitle: "Error message",
    errorWrongReasonNumber: "Wrong Reason Count Must be a Number",
    errorNoWarrantyNumber: "No Warranty Count Must be a Number",
    errorMessage: "SAV Check Result Update Failed",
    "-401002": "Input parameter error",
    "-401003": "The Tracking Number is wrong",
    successTitle: "Success message",
    successCheckNumber: "SAV Check Result is Updated",
    warnTitle: "Warning",
    warnCheckNumber: "The decimal has been rounded. Please note the final result",
    infoTitle: "Tips",
  },
  createRMA: {
    PleaseInputFirstName: "Please insert First name",
    PleaseInputLastName: "Please insert Last name",
    PleaseInputThePhoneCode: "Please input the phone code",
    PleaseInputThePhone: "Please input the phone",
    PleaseInputOnlyNumbers: "Please input a valid phone number",
    PleaseSelectProvince: "Please select the province",
    PleaseSelectCountry: "Please select the country",
    PleaseInputAddress: "Please input the address",
    PleaseInputReceiverName: "Please input receiver name",
    PleaseInputReceiverPhone: "Invalid phone number",
    IncorrectPhone: "Incorrect phone number format",
    ModifyInCRM: "modify it in the CRM and try again",
    PleaseInputEmail: "Please insert valid email",
    ProductReasonsAreMandatory : "Product Reasons Are Mandatory",
    ProductDateAreMandatory: "Date Of Purchase is Mandatory",
    OriginalOrderIdAreMandatory: "Original Order ID is Mandatory",
    OriginalOrderIdAreMandatory: "Original Order No. is Mandatory",
    PleaseEnsureAllProductHaveASelectedReason: "Please Ensure All Product Have A Selected Reason",
    ProductMaintenanceAmountAreMandatory: "Maintenance Amount is Mandatory",
    ProductWaivedFeeAndCommentsAreMandatory: "Store Remark is Mandatory When Request Waived Fee",
    ProductInvoiceNumberAreMandatory: "Invoice Number is Mandatory",
    GoldProductInfoAreMandatory: "Please check the sequence number of Gold Product",
    TotalWeightAreMandatory: "Total Weight Are Mandatory",
    Country: "Country",
    City: "City",
    FulfilledDate: "Fulfilled Date",
    Type: "Buying Platform",
    Language: "Language",
    CountryIsMandatory: "Country is Mandatory",
    CityIsMandatory: "City is Mandatory",
    FulfilledDateIsMandatory: "Fulfilled Date is Mandatory",
    TypeIsMandatory: "Type is Mandatory",
    LanguageIsMandatory: "Language is Mandatory",
    PleaseSelectAtLeastRenewReason: "Please select at least one of the Renew reason on the right",
    Allow: 'Allow',
    Deny: 'Deny',
    isNotAllowSendToCustomer: 'The current country does not allow sending customers',
    pleaseCheckInvoiceAgain: 'Please check invoice number again',
    invoiceIsNotExit: 'Invoice number is not exit',
    invoiceIsNotIncludeShippingInfo: 'The invoice is not include shipping fee infomation',
    checkInvoiceError: 'Check invoice error, please try again'
  },
  sysAdmin: {
    syncTS: "Async TypeSense Now",
    importProduct: "Import Product to Typesense",
    Async: 'Async',
    rma_id: 'RMA ID',
    start_date: 'Start Date',
    end_date: 'End Date',
    limit: 'Limit Clear Order Count',
    valid_date_of_pruchase: 'Valid Date of Pruchase',
    clear: 'Clear',
    DoYouWantToClearOrder: 'Do you want to clear these orders?',
    EndDateIsRequired: 'End Date is Required'
  },
  InvalidParameter: {
    TrackingNo: 'This Tracking Number is not bound to any RMA',
    Locked: 'This tracking number has been used',
    Country: 'The store is not outside China',
    commonSuccess: 'RMA Orders have been sent to NAV',
    commonError: 'RMA Orders sent to NAV failure',
    TrackingNoNotSent: 'This Tracking Number is not send to NAV'
  },
  sendPackageToNAV: {
    DomesticCurrencyValue: 'Domestic Currency Value',
    dataNotFound: 'Data is not found',
    TotalQuantity: 'Total Quantity',
    TotalValue: 'Total Value',
    TotalWeight: 'Total Weight'
  },
  customerStatus:{
    title: 'APM Monaco repair request',
    apmSale: 'After Sales Service',
    customerName: 'Customer Name',
    shopName: 'Store',
    shopPhone: 'Store Phone',
    shopEmail: 'Email address',
    created: 'Created',
    productReference: 'Reference',
    repairFee: 'Repair Fee',
    repairReason: 'Repair Reason',
    clickText: 'Click here to follow your repair',
    enjoyText: 'Enjoy shopping on www.apm.mc',
    tipsText: 'We are available 24 hours a day, 7 days a week',
    step1Title: 'Step 1',
    step2Title: 'Step 2',
    step3Title: 'Step 3',
    step4Title: 'Step 4',
    step5Title: 'Step 5',
    step6Title: 'Step 6',
    step1Content: 'Deposit to the APM Boutique',
    step2Content: 'Transit to Repair Center',
    step3Content: 'Repair Center',
    step4Content: 'Transit to APM Boutique',
    step5Content: 'Ready for pick up',
    step6Content: 'Picked up',
    orderID: 'Order No',
    orderName: 'Order ID',
    trackingNumber: 'Tracking Number',
    userName:'Name',
    userEmail: 'Email',
    userPhone: 'Phone',
  },
  transferFactory: {
    clickImportButton: 'Click Import Button to Save Data',
    cannotClickBecauseBlankTracking: "Can't Click Because of Blank Scanned Item",
    dataIsSaved: 'Data is Saved to List'
  },
  captcha: {
    captchaError: "Captcha fail, please refresh and try again",
    updateCaptchaLater: "Captcha updates are too frequent"
  },
  reasonManage: {
    subReason: 'SubReasons',
    name: 'Reason Name',
    IsActive: 'Is Active',
    Active: 'Active',
    InActive: 'Inactive',
    IsWarranty: 'Is Warranty',
    sort: 'Sort',
    CreatedBy: 'Created By',
    addSubReason: 'Add SubReason',
    editSubReason: 'Edit SubReason',
    SubReasonBy: 'SubReason By',
    SubReasonName: 'SubReason Name',
    SubReasonDesc: 'Description',
    SubReasonNameIsRequired: 'SubReason Name is required!',
    SubReasonIsupdated: 'SubReason is updated!',
    pleaseSelectSubReason: 'Please select subReason',
  },
  reasonsName: {
    "InvalidOrder(Duplicate)": '无效单据 (重复)',
    "NotAPMProduct": '非APM产品',
    "InconsistentwithSAVPolicy": '不符合售后政策',
    "LockDefective": '锁扣损坏',
    "WrongProduct": '产品错误',
    Renew: "Renew",
    RenewTarnished: 'Renew: Tarnished',
    RenewStoneMissing: 'Renew: Stone Missing',
    RenewPearlMissing: 'Renew: Pearl Missing',
    RenewDefectiveLock: 'Renew: Defective Lock',
    RenewBroken: 'Renew: Broken',
    RenewDeformed: 'Renew: Deformed',
    RenewColorFadingUnderWarranty: 'Renew: Color Fading',
    RenewResizing: 'Renew: Resizing',
    RenewEarringPinIssue: 'Renew: Earring Pin Issue',
    Tarnished: "Tarnished",
    "Pen-platingTarnished": "Pen-plating Tarnished",
    "NormalPlatingTarnished": "Normal Plating Tarnished",
    StoneMissing: "Stone Missing",
    "NeedChangeStone(DirtyorCrack)": "Need Change Stone (Dirty or Crack)",
    PearlMissing: "Pearl Missing",
    NacreMissing: "Nacre Missing",
    Scratched: "Scratched",
    ComponentMissing: "Component Missing",
    MainPartMissing: "Main part Missing",
    DefectiveLock: "Defective Lock",
    SliderDefect: "Slider Defect",
    EarringLockDefect: "Earring Lock Defect",
    "Bracelet&BangleLockDefect": "Bracelet & Bangle Lock Defect",
    "Bangle&EarringHingeJointDefect": "Bangle & Earring Hinge Joint Defect",
    EarPinCrack: "Ear-pin Crack",
    Broken: "Broken",
    ChainBroken: "Chain Broken",
    WeldingPointBroken: "Welding Point Broken",
    EarringPinBroken: "Earring Pin Broken",
    MainPartBroken: "Main Part Broken",
    Deformed : "Deformed",
    WebsiteOnlineRefund: "Website online refund",
    ColorFading: "Color Fading",
    ColorFadingUnderWarranty: "Color Fading",
    AbrasionAfterUsed: "Abrasion After Used",
    ColorFadingAfterUsed: "Color Fading After Used",
    AbnormalPeelOff: "Abnormal Peel Off",
    Resurface: "Resurface",
    OptionalComments: "Optional Comments",
    BrokenByClient: "Broken By Client",
    DeformedByClient: "Deformed By Client",
    Resizing: 'Resizing',
    EarringPinIssue: 'Earring Pin Issue',
    sub: 'SubReason:'
  },
  QualityInvestigate: {
    OriginalProduceFactory: "Original Produce Factory",
    QualityInvestigate: "Quality Investigate",
    ExpireWarranty: "Expire warranty",
    SAVRepairIssue: "SAV Repair Issue",
    ProductionIssue: "Production Issue",
    DesignIssue: "Design Issue",
    ComponentsIssue: "Components Issue",
    CustomersIssue: "Customers Issue",
  }
};
