import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css'
// import langData from  './assets/langData'
import i18n from './lang'; //Vuei18
import VueTelInput from 'vue-tel-input'
import axios from 'axios'
import permissionMixin from './mixins/permissionMixin';
import storageUtils from '@/utils/storage';
import VueSignaturePad from 'vue-signature-pad';

// Load permission from local storage to global object
function loadPermission(){
  if (storageUtils.isUser()) {
    const arr = JSON.parse(storageUtils.getLoginUser());
    if(arr.allPermission){
      return arr.allPermission.map(x => x.name);
    }
    return [];
  }
}

const app = createApp(App)

// Assign all permission to global object
app.config.globalProperties.$permissionArr = loadPermission();
app.mixin(permissionMixin)

app.use(i18n)
app.use(Antd)
app.use(store)
app.use(router)
app.use(VueTelInput)
app.use(VueSignaturePad)
window.axios = axios

app.mount('#app')