export default {
  customerStatus:{
      title: 'APM Monaco維修申請',
      apmSale: '售後服務',
      customerName: '客戶姓名',
      shopName: '店鋪',
      shopPhone: '店鋪電話',
      shopEmail: '郵箱地址',
      created: '已創建',
      productReference: '參考編號',
      repairFee: '維修費用',
      repairReason: '維修原因',
      clickText: '點擊此處查看您的維修進度',
      enjoyText: '在 www.apm.mc 享受購物',
      tipsText: '我們每天 24 小時， 每週七天為您提供服務',
      step1Title: '步驟一',
      step2Title: '步驟二',
      step3Title: '步驟三',
      step4Title: '步驟四',
      step5Title: '步驟五',
      step6Title: '步驟六',
      step1Content: '存放在APM店鋪',
      step2Content: '運送至維修中心',
      step3Content: '維修中心',
      step4Content: '運送至APM店鋪',
      step5Content: '可取貨',
      step6Content: '已取貨',
      orderID: '訂單編號',
      orderName: '訂單名稱',
      trackingNumber: '追蹤單號',
      userName:'姓名',
      userEmail: '電子郵箱',
      userPhone: '電話',
    }
  };
  