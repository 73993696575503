import { createStore } from 'vuex'
import modules from './modules'
import getters from './getters'
import createPersistedState from 'vuex-persistedstate'


export default createStore({
  modules,
  getters,
  plugins: [createPersistedState()],
})
