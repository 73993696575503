import request from '@/utils/axios';

export function login(data) {
  return request({
    url: `/auth/callback?query=${data.query}`,
    method: 'get',
    data: data
  });
}

export function authCallback(data) {
  return request({
    url: `/auth/callback?code=${data.code}&state=${data.state}&query=${data.query}`,
    method: 'get',
    data: data,
  });
}

export function loginOld(data) {
  return request({
    url: `/auth/loginold`,
    method: 'post',
    data: data,
  });
}

export function logout() {
  return request({
    url: '/auth/logout',
    method: 'get',
  });
}

export function statusRMA(data) {
  return request({
    url: `/rma/currenct/status?rma_id=${data}`,
    method: 'get',
  });
}

export function getCaptcha() {
  return request({
    url: `/captcha/getOne`,
    method: 'get',
  });
}
