<template>
  <router-view/>
</template>

<script> 

  export default {
    name: 'Home',
    mounted(){ 
      
    },
  
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%!important;
}
.ant-input-clear-icon {
  font-size: 17px!important;
}

.full-modal {
  .ant-modal {
    max-width: 100%;
    width: 100%;
    height: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
    padding: 0;
  }
  .ant-modal-footer {
    background: white;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
</style>