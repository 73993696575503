export const OPERATION_DIVISION = Object.freeze({
  RECEIVE: {
    name: "ReceiveFromShop",
    id: 3
  },
  REPAIRPROCESS: {
    name: "RepairProcess",
    id: 4
  },
  REPAIRPOLISH: {
    name: "RepairPolish",
    id: 5
  },
  REPAIRQC: {
    name: "RepairQC",
    id: 6
  },
  PLATING: {
    name: "PlatingProcess",
    id: 7
  },
  ASSEMPLYPROCESS: {
    name: "AssemblyProcess",
    id: 8
  },
  ASSEMPLYPEN: {
    name: "AssemblyPenPlatingProcess",
    id: 9
  },
  ASSEMPLYSETSTONE: {
    name: "AssemblySettingStone",
    id: 10
  },
  ASSEMPLYSETPEARL: {
    name: "AssemblySettingPearl",
    id: 11
  },
  QCFINAL: {
    name: "FinalQCProcess",
    id: 12
  },
  FINISHEDREPAIR: {
    name: "RepairDone",
    id: 13
  },
  LOGISTICSHIPOUT: {
    name: "LogisticsShipOut",
    id: 14
  },
  SHOPRECEIVED: {
    name: "ShopReceived",
    id: 15
  },
  CLIENTTAKE: {
    name: "TakenByClient",
    id: 16
  },
  SAVCHECKRESULT: {
    name: "SAVCheckResult",
    id: 17
  },
  MCTOCN: {
    name: "MCToCN",
    id: 18
  }
});