<template>
  <a-layout>
    <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible>
      <transition mode="out-in" name="component-fade">
        <div class="logo" style="text-align: center; padding: 20px">
          <img class="apm_icon" src="https://weappimg.apm-monaco.cn/197/logo-transparent.png" />
          <span v-if="!collapsed" style="color: white; font-size:28px;">RMA</span>
        </div>
      </transition>
      
      <a-menu class="leftmenu" theme="dark" mode="inline" v-model:selectedKeys="selectedKeys">
        <a-menu-item :key="isShop ? '/dashboard' : '/operation/dashboard'" v-if="permission('#INTRANET::FRONT_view home page')">
          <router-link :to="isShop ? '/dashboard' : '/operation/dashboard'" style="color: white">
            <user-outlined />
            <span>{{ $t('route.Dashboard') }}</span>
          </router-link>
        </a-menu-item>
        <a-sub-menu key="rmacreation" v-if="permission('#INTRANET::FRONT_view sidebar group rma')">
          <template #title>
            <span>
              <video-camera-outlined />
              <span>{{ $t('route.rmadetails') }}</span>
            </span>
          </template>
          <a-menu-item-group>
            <a-menu-item key="/listRma" v-if="permission('#INTRANET::FRONT_view list all rma page')">
              <router-link to="/listRma" style="color: white">
                {{ $t('route.listRma') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="/clientSearch" v-if="permission('#INTRANET::FRONT_view (shop) get all rma')">
              <router-link to="/clientSearch" style="color: white">
                {{ $t('route.clientSearch') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="/createRma" v-if="permission('#INTRANET::FRONT_view request new rma page')">
              <router-link
                to="/createRma"
                style="color: white">
                {{ $t('route.CreateRma') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="/preparepackage" v-if="permission('#INTRANET::FRONT_view prepare package page')">
              <router-link to="/preparepackage" style="color: white">
                {{ $t('route.PreparePackage') }}
              </router-link>
            </a-menu-item>
            <a-menu-item key="/receivedpackage" v-if="permission('#INTRANET::FRONT_view receive package page')">
              <router-link to="/receivedpackage" style="color: white">
                {{ $t('route.ReceivedPackage') }}
              </router-link>
            </a-menu-item> 
          </a-menu-item-group>
        </a-sub-menu>
        <a-menu-item key="/operation/listRma" v-if="permission('#INTRANET::FRONT_view list all rma page for operation')">
          <router-link to="/operation/listRma" style="color: white">
            <team-outlined />
            <span>{{ $t('route.listRma') }}</span>
          </router-link>
        </a-menu-item>

        <!-- <a-menu-item
          key="175"
          v-if="isLogged() && permission('#INTRANET::FRONT_view manage factory worker permission')">
          <router-link
            to="/operation/manage-user"
            style="color: white"
          >
            <team-outlined />
            <span>Manage Workers </span>
          </router-link>
        </a-menu-item> -->
        <a-sub-menu key="workers" v-if="isLogged() && permission('#INTRANET::FRONT_view manage factory worker permission')">
          <template #title>
            <span>
              <TableOutlined />
              <span>{{ $t('route.Worker') }}</span>
            </span>
          </template>
          <a-menu-item-group>
            <a-menu-item
              key="/worker-list"
              v-if="isLogged() && permission('#INTRANET::FRONT_view manage factory worker permission')">
              <router-link
                to="/worker-list"
                style="color: white"
              >
                <team-outlined />
                <span> {{ $t('route.ListWorkers') }} </span>
              </router-link>
            </a-menu-item>
            <a-menu-item
              key="/workers-roles"
              v-if="isLogged() && permission('#INTRANET::FRONT_view manage factory worker permission')">
              <router-link
                to="/workers-roles"
                style="color: white"
              >
                <team-outlined />
                <span> {{ $t('route.WorkerRoles') }}</span>
              </router-link>
            </a-menu-item>
          </a-menu-item-group>
        </a-sub-menu>
        <a-sub-menu key="rmaoperation" v-if="permission('#INTRANET::FRONT_view sidebar group rma operation')">
          <template #title>
            <span>
              <TableOutlined />
              <span>{{ $t('route.RMAOperations') }}</span>
            </span>
          </template>
          <a-menu-item-group>
            <a-menu-item :key="'/operation/' + OPERATION_DIVISION.RECEIVE.id + '/'+ OPERATION_DIVISION.RECEIVE.name" v-if="permission('#INTRANET::FRONT_view (factory) receive from shop')">
              <router-link :to="'/operation/' + OPERATION_DIVISION.RECEIVE.id + '/'+ OPERATION_DIVISION.RECEIVE.name" style="color: white">
                {{ $t('route.ReceivedPackage') }}
              </router-link>
            </a-menu-item>
            <a-sub-menu key="rmarepair" v-if="permission('#INTRANET::FRONT_view (factory) (repair) operation group')">
              <template #title>
                <span>
                  <TableOutlined />
                  <span>{{ $t('route.RMAOperations') }}</span>
                </span>
              </template>
              <a-menu-item-group>
                <a-menu-item :key="'/operation/' + OPERATION_DIVISION.REPAIRPROCESS.id + '/' + OPERATION_DIVISION.REPAIRPROCESS.name" v-if="permission('#INTRANET::FRONT_view (factory) (repair) operation process')">
                  <router-link
                    :to="'/operation/' + OPERATION_DIVISION.REPAIRPROCESS.id + '/' + OPERATION_DIVISION.REPAIRPROCESS.name"
                    style="color: white">
                    {{ $t('route.RepairProcess') }}
                  </router-link>
                </a-menu-item>
                <a-menu-item :key="'/operation/' + OPERATION_DIVISION.REPAIRPOLISH.id + '/' + OPERATION_DIVISION.REPAIRPOLISH.name" v-if="permission('#INTRANET::FRONT_view (factory) (repair) operation polishing process')">
                  <router-link
                    :to="'/operation/' + OPERATION_DIVISION.REPAIRPOLISH.id + '/' + OPERATION_DIVISION.REPAIRPOLISH.name"
                    style="color: white"
                  >
                    {{ $t('route.PolishingProcess') }}
                  </router-link>
                </a-menu-item>
                <a-menu-item :key="'/operation/'+ OPERATION_DIVISION.REPAIRQC.id + '/' + OPERATION_DIVISION.REPAIRQC.name" v-if="permission('#INTRANET::FRONT_view (factory) (repair) QC process')">
                  <router-link
                    :to="'/operation/'+ OPERATION_DIVISION.REPAIRQC.id + '/' + OPERATION_DIVISION.REPAIRQC.name"
                    style="color: white"
                  >
                    {{ $t('route.QCProcess') }}
                  </router-link>
                </a-menu-item>
              </a-menu-item-group>
              <!--
              <a-menu-item key="12" v-if="permission('#INTRANET::FRONT_view (factory) (repair) operation polishing process')">
                <router-link
                  :to="'/operation/' + OPERATION_DIVISION.REPAIRPOLISH.id + '/' + OPERATION_DIVISION.REPAIRPOLISH.name"
                  style="color: white"
                >
                  Polishing Process
                </router-link>
              </a-menu-item>
              -->
              <!--
              <a-menu-item key="13" v-if="permission('#INTRANET::FRONT_view (factory) (repair) QC process')">
                <router-link
                  :to="'/operation/'+ OPERATION_DIVISION.REPAIRQC.id + '/' + OPERATION_DIVISION.REPAIRQC.name"
                  style="color: white"
                >
                  QC Process
                </router-link>
              </a-menu-item>
              -->
            </a-sub-menu>
            <a-menu-item :key="'/operation/' + OPERATION_DIVISION.PLATING.id + '/' + OPERATION_DIVISION.PLATING.name" v-if="permission('#INTRANET::FRONT_view (factory) send to planting')">
              <router-link
                :to="'/operation/' + OPERATION_DIVISION.PLATING.id + '/' + OPERATION_DIVISION.PLATING.name"
                style="color: white">
                {{ $t('route.PlatingProcess') }}
              </router-link>
            </a-menu-item>
            <a-sub-menu key="rmaassembly" v-if="permission('#INTRANET::FRONT_view (factory) (assembly) group')">
              <template #title>
                <span>
                  <TableOutlined />
                  <span>{{ $t('route.Assembly') }}</span>
                </span>
              </template>
              <a-menu-item-group>
                <a-menu-item :key="'/operation/'+ OPERATION_DIVISION.ASSEMPLYPROCESS.id + '/' + OPERATION_DIVISION.ASSEMPLYPROCESS.name" v-if="permission('#INTRANET::FRONT_view (factory) (assembly) process')">
                  <router-link
                    :to="'/operation/'+ OPERATION_DIVISION.ASSEMPLYPROCESS.id + '/' + OPERATION_DIVISION.ASSEMPLYPROCESS.name"
                    style="color: white">
                    {{ $t('route.AssemblyProcess') }}
                  </router-link>
                </a-menu-item>
                <a-menu-item :key="'/operation/' + OPERATION_DIVISION.ASSEMPLYPEN.id + '/' + OPERATION_DIVISION.ASSEMPLYPEN.name" v-if="permission('#INTRANET::FRONT_view (factory) (assembly) pen plating process')">
                  <router-link
                    :to="'/operation/' + OPERATION_DIVISION.ASSEMPLYPEN.id + '/' + OPERATION_DIVISION.ASSEMPLYPEN.name"
                    style="color: white"
                  >
                    {{ $t('route.PenPlatingProcess') }}
                  </router-link>
                </a-menu-item>
                <a-menu-item :key="'/operation/' + OPERATION_DIVISION.ASSEMPLYSETSTONE.id + '/' + OPERATION_DIVISION.ASSEMPLYSETSTONE.name" v-if="permission('#INTRANET::FRONT_view (factory) (assembly) setting stone')">
                  <router-link
                    :to="'/operation/' + OPERATION_DIVISION.ASSEMPLYSETSTONE.id + '/' + OPERATION_DIVISION.ASSEMPLYSETSTONE.name"
                    style="color: white"
                  >
                    {{ $t('route.SettingStone') }}
                  </router-link>
                </a-menu-item>
                <a-menu-item :key="'/operation/' + OPERATION_DIVISION.ASSEMPLYSETPEARL.id + '/' + OPERATION_DIVISION.ASSEMPLYSETPEARL.name" v-if="permission('#INTRANET::FRONT_view (factory) (assembly) setting pearl')">
                  <router-link
                    :to="'/operation/' + OPERATION_DIVISION.ASSEMPLYSETPEARL.id + '/' + OPERATION_DIVISION.ASSEMPLYSETPEARL.name"
                    style="color: white"
                  >
                    {{ $t('route.SettingPearl') }}
                  </router-link>
                </a-menu-item>
              </a-menu-item-group>
            </a-sub-menu>
            <a-menu-item :key="'/operation/' + OPERATION_DIVISION.QCFINAL.id + '/' +OPERATION_DIVISION.QCFINAL.name" v-if="permission('#INTRANET::FRONT_view (factory) QC final')">
              <router-link
                :to="'/operation/' + OPERATION_DIVISION.QCFINAL.id + '/' +OPERATION_DIVISION.QCFINAL.name"
                style="color: white">
                {{ $t('route.QCFinal') }}
              </router-link>
            </a-menu-item>
            <a-menu-item :key="'/operation/' + OPERATION_DIVISION.FINISHEDREPAIR.id + '/' + OPERATION_DIVISION.FINISHEDREPAIR.name" v-if="permission('#INTRANET::FRONT_view (factory) repaired')">
              <router-link
                :to="'/operation/' + OPERATION_DIVISION.FINISHEDREPAIR.id + '/' + OPERATION_DIVISION.FINISHEDREPAIR.name"
                style="color: white">
                {{ $t('route.Repaired') }}
              </router-link>
            </a-menu-item>
            <a-menu-item :key="'/operation/' + OPERATION_DIVISION.LOGISTICSHIPOUT.id + '/' + OPERATION_DIVISION.LOGISTICSHIPOUT.name" v-if="permission('#INTRANET::FRONT_view (factory) logistic ship out')">
              <router-link
                :to="'/operation/' + OPERATION_DIVISION.LOGISTICSHIPOUT.id + '/' + OPERATION_DIVISION.LOGISTICSHIPOUT.name"
                style="color: white">
                {{ $t('route.LogisticShipOut') }}
              </router-link>
            </a-menu-item>
            <a-menu-item :key="'/operation/' + OPERATION_DIVISION.MCTOCN.id + '/' + OPERATION_DIVISION.MCTOCN.name" v-if="permission('#INTRANET::FRONT_view (factory) package forwarding')">
              <router-link
                :to="'/operation/' + OPERATION_DIVISION.MCTOCN.id + '/' + OPERATION_DIVISION.MCTOCN.name"
                style="color: white">
                {{ $t('route.MCToCN') }}
              </router-link>
            </a-menu-item>
            <!-- <a-menu-item :key="'/operation/' + OPERATION_DIVISION.SAVCHECKRESULT.id + '/' + OPERATION_DIVISION.SAVCHECKRESULT.name" v-if="permission('#INTRANET::FRONT_view (factory) operation sav add')">
              <router-link
                :to="'/operation/' + OPERATION_DIVISION.SAVCHECKRESULT.id + '/' + OPERATION_DIVISION.SAVCHECKRESULT.name"
                style="color: white">
                {{ $t('SAVCheck.SAVCheckResult') }}
              </router-link>
            </a-menu-item> -->
          </a-menu-item-group>
        </a-sub-menu>
        <a-sub-menu key="system" v-if="permission('#RMA::ENGINE_api system config')">
          <template #title>
            <span>
              <SettingOutlined />
              <span>{{ $t('route.systemManagement') }}</span>
            </span>
          </template>
          <a-menu-item-group>
            <a-menu-item key="/system/systemConfig" v-if="permission('#INTRANET::FRONT_view system config')">
              <router-link
                to="/system/systemConfig"
                style="color: white"
              >
                <SettingOutlined />
                <span> {{ $t('route.systemConfig') }} </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="/system/orderClear" v-if="permission('#INTRANET::FRONT_view batch update rma(logistic ship out)')">
              <router-link
                to="/system/orderClear"
                style="color: white"
              >
                <SettingOutlined />
                <span> {{ $t('route.orderClear') }} </span>
              </router-link>
            </a-menu-item>
            <a-menu-item key="/system/subReasonManage" v-if="permission('#INTRANET::FRONT_view (system) subreasons')">
              <router-link
                to="/system/subReasonManage"
                style="color: white"
              >
                <SettingOutlined />
                <span> {{ $t('route.subReasonManage') }} </span>
              </router-link>
            </a-menu-item>
          </a-menu-item-group>
        </a-sub-menu>
        <!-- <a-sub-menu key="customercare">
            <template #title>
              <span>
                <MailOutlined />
                <video-camera-outlined />
                <span>Customer Care</span>
              </span>
            </template>
            <a-menu-item-group>
              <router-link to="/searchRma" style="color: white;"><a-menu-item key="3">Search RMA</a-menu-item></router-link>
            </a-menu-item-group>
          </a-sub-menu>
          <a-sub-menu key="admin">
            <template #title>
              <span>
                <MailOutlined />
                <video-camera-outlined />
                <span>Admin</span>
              </span>
            </template>
            <a-menu-item-group>
              <router-link to="/receiveFromShop" style="color: white;"><a-menu-item key="3">Users</a-menu-item></router-link>
              <router-link to="/sendToRepair" style="color: white;"><a-menu-item key="4">Roles</a-menu-item></router-link>
              <router-link to="/sendToPlating" style="color: white;"><a-menu-item key="5">Permissions</a-menu-item></router-link>
            </a-menu-item-group>
          </a-sub-menu> -->
        <router-link to="/login" style="color: white" v-if="isLogged() === false">
          <a-menu-item key="14">
            <login-outlined />
            <span>{{ $t('route.Login') }}</span>
          </a-menu-item>
        </router-link>
        <!--Footer menu bar  -->
        <a-menu-item
          key="17x"
          v-if="isLogged() && isWorkerLogged() && !permission('#INTRANET::FRONT_view manage factory worker permission')"
          @click="switchWorker()">
          <UserSwitchOutlined />
          <span>Switch Worker</span>
        </a-menu-item>
        <a-menu-item
          key="lang"
          style="background-color: #001529;color: black;"
          class="langswitch"
        >
          <LangSwitch/>
        </a-menu-item>
        <a-menu-item
          key="18"
          v-if="isLogged()"
          @click="logout()"
          style="background-color: #c3370d;">
          <logout-outlined />
          <span>{{ $t('navbar.logOut') }}</span>
        </a-menu-item>
      </a-menu>
      <a-menu class="leftmenu" theme="dark" mode="inline" v-if="!store.getters.userPerm">
        <a-menu-item
          key="18"
          @click="logout()"
          style="background-color: #c3370d;">
          <logout-outlined />
          <span>{{ $t('navbar.logOut') }}</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="() => (collapsed = !collapsed)"
          style="padding-left: 20px"
        />
        <menu-fold-outlined
          v-else
          class="trigger"
          @click="() => (collapsed = !collapsed)"
          style="padding-left: 20px"
        />
        {{ $t('route.'+pathName) }}
        <span style="float: right;padding-right:20px">
          <b>{{ store.getters.name + ' ' + store.getters.subname }}</b>
        </span>
      </a-layout-header>
      <a-layout-content
        :style="{
          marginTop: '3px',
          background: '#fff',
          minHeight: '280px',
        }"
      >
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
  import {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    LoginOutlined,
    LogoutOutlined,
    TableOutlined,
    UserSwitchOutlined,
    TeamOutlined,
    SettingOutlined
  } from '@ant-design/icons-vue'
  import { defineComponent, ref, watch, computed, onMounted } from 'vue'
  import { OPERATION_DIVISION } from '@/store/constants'
  import { useStore } from 'vuex'
  import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router'
  import menu from '@/router/menu'
  import LangSwitch from '@/components/LangSwitch.vue';
  import { useI18n } from 'vue-i18n'
  import storageUtils from '@/utils/storage';
  import ListRmaState from '@/state/ListRma'

  export default defineComponent({
    components: {
      UserOutlined,
      VideoCameraOutlined,
      UploadOutlined,
      MenuUnfoldOutlined,
      MenuFoldOutlined,
      LoginOutlined,
      LogoutOutlined,
      TableOutlined,
      UserSwitchOutlined,
      TeamOutlined,
      LangSwitch,
      SettingOutlined
    },
    setup() {
      const route = useRoute()
      const router = useRouter()
      const store = useStore()
      const userName = ref("")
      const selectedKeys = ref()
      // const pageTitle = ref("")
      const { t ,locale } = useI18n({ useScope: 'global' })
      const isShop = localStorage.getItem("usertype") === "shop"
      const { state } = ListRmaState()

      const logout = () => {
        state.searchData = {}
        if(storageUtils.getLoginType() == 'loginNew'){
          store.dispatch('auth/logout')
        }else{
          store.dispatch('auth/logoutOld')
        }        
      }

      // userName.value = localStorage.getItem("last_name")
      // if (localStorage.getItem("navcode") !== "") {
      //   userName.value += " (" + localStorage.getItem("navcode") + ")"
      // }

      // console.log(store)
      userName.value = store.getters.name

      // same as beforeRouteUpdate option with no access to `this`
      // pageTitle.value = menu[router.currentRoute.value.name]

      // menuName = menu[router.currentRoute.value.name]
      // console.log(t('route.'+ router.currentRoute.value.name +''),"t('route.'+ router.currentRoute.value.name +'')")

      // pageTitle.value = t('route.'+ router.currentRoute.value.name +'')

      const pathName = ref("")
      pathName.value = router.currentRoute.value.name

      onMounted(() => {
        selectedKeys.value = [route.path]
      })

      onBeforeRouteUpdate(async (to, from) => {
        pathName.value = to.name
        selectedKeys.value = [to.path]
      })

      // console.log(to, "to000000")
      // pageTitle.value = t('route.'+to.name)

      return {
        menu,
        userName,
        store,
        // pageTitle,
        selectedKeys,
        collapsed: ref(false),
        OPERATION_DIVISION,
        logout,
        pathName,
        t, locale,
        isShop
      }
    }
  })
</script>
<style lang="scss" scoped>
.apm_icon {
  width: 40%;
  margin-right: 20px;
}

.home {
  padding: 10px;
}

.ant-layout {
  height: 100%;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

// .leftmenu{
//   max-height:calc(100% - 155px);
//     overflow:scroll;
// }

</style>

<style>

  .ant-layout {
    height: auto !important;
    min-height: 100% !important;
  }

  .lang_switch_small {
    display:none;
  }

  .lang_switch_large {
    display:inline-block;
  }

  .ant-menu-inline-collapsed .langswitch.ant-menu-item {
    padding:0 !important;
    margin:0 !important;
  }
  .ant-menu-inline-collapsed .lang_switch_small {
    display:inline-block;
  }
  .ant-menu-inline-collapsed .lang_switch_large {
    display:none;
  }

  .langswitch {
    padding-left:48px;
  }

  .ant-menu-inline-collapsed .langswitch {
    padding-left:4px;
  }

  .langswitch
  .lang_tag_switch span {
    padding:8px 14px;
  }

  .ant-menu-inline-collapsed .langswitch
  .lang_tag_switch span {
    padding:8px;
  }
</style>