import SecureLS from "secure-ls";

/**
    Absctract utility to query storage info can easely switch to cookies for exemple
 */
const token = 'rmaToken';
const user = 'rmaUser';
const worker = 'workerUser'
const lang = 'defaultLang';
const menuState = 'menuState';
const ls =  new SecureLS({encodingType: 'aes', isCompression: false });
const loginType = 'loginType';


//!! this does not verify the token is valid or genuine (service or api)
function isLogged() {
  return !!ls.get(token)
}

function isUser() {
  return !!ls.get(user)
}

function setLoginToken(userToken) {
  return ls.set(token, userToken)
}

function getLoginToken() {
  return ls.get(token)
}

function setLoginUser(rmaUser) {
  return ls.set(user, rmaUser)
}

function removeLoginUser() {
  return ls.remove(user)
}

function getLoginUser() {
  return ls.get(user)
}

function setWorkderUser(workerUser) {
  return ls.set(worker, workerUser)
}

function removeWorkerUser() {
  return ls.remove(worker)
}

function getWorkerUser() {
  return ls.get(worker)
}

function removeLoginToken() {
  return ls.remove(token)
}

function removeAllCredential() {
  removeLoginToken()
  removeLoginUser()
  removeWorkerUser()
}

function setUserLang(userlang) {
  return localStorage.setItem(lang, userlang);
}

function getUserLang() {
  return localStorage.getItem(lang);
}

function setMenuState(state) {
  return localStorage.setItem(menuState, state);
}

function getMenuState() {
  return !!localStorage.getItem(menuState);
}

function getLoginType() {
  return localStorage.getItem(loginType);
}

function setLoginType(state) {
  return localStorage.setItem(loginType, state);
}

const loginTimes = 'loginTimes';
const loginBtnDisabledTime = 'loginBtnDisabledTime'

function getLoginTimes() {
  return localStorage.getItem(loginTimes);
}

function setLoginTimes(rmaLoginTimes) {
  return localStorage.setItem(loginTimes, rmaLoginTimes);
}

function removeLoginTimes() {
  return localStorage.removeItem(loginTimes);
}

function getLoginBtnDisabledTime() {
  return localStorage.getItem(loginBtnDisabledTime);
}

function setLoginBtnDisabledTime(rmaLoginBtnDisabledTime) {
  return localStorage.setItem(loginBtnDisabledTime, rmaLoginBtnDisabledTime);
}

function removeLoginBtnDisabledTime() {
  return localStorage.removeItem(loginBtnDisabledTime);
}

export default {
  isLogged,
  setLoginToken,
  getLoginToken,
  removeLoginToken,
  setUserLang,
  getUserLang,
  getMenuState,
  setMenuState,
  getLoginUser,
  setLoginUser,
  isUser,
  removeAllCredential,
  removeWorkerUser,
  getWorkerUser,
  setWorkderUser,
  getLoginType,
  setLoginType,
  getLoginTimes,
  setLoginTimes,
  removeLoginTimes,
  getLoginBtnDisabledTime,
  setLoginBtnDisabledTime,
  removeLoginBtnDisabledTime
}