import AppLayoutMenu from '../layouts/AppLayoutMenu'
import Login from '../views/auth/Login.vue'
import CustomerStatus from '../views/customerStatus/customerStatus.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    redirect: 'dashboard',
    component: AppLayoutMenu,
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import( './../views/Dashboard'),
      },
      {
        path: '/receiveFromShop',
        name: 'ReceiveFromShop',
        component: () => import( './../views/warehouse/Operation'),
      },
      {
        path: '/sendToRepair',
        name: 'SendToRepair',
        component: () => import( './../views/warehouse/SendToRepair'),
      },
      {
        path: '/sendToPlating',
        name: 'SendToPlating',
        component: () => import( './../views/warehouse/SendToPlating'),
      },
      {
        path: '/receiveFromRepair',
        name: 'ReceiveFromRepair',
        component: () => import( './../views/warehouse/ReceiveFromRepair'),
      },
      {
        path: '/operation/:divisionId/:divisionName/',
        name: 'RMAOperations',
        component: () => import('./../views/warehouse/Operation'),
        props: true //allow props to be passed
      },

      // New two routes to handlew worker permission
      { 
        path: '/workers-roles',
        name: 'WorkerRoles',
        // component: () => import('./../views/warehouse/WarehouseManageUser'),
        component: () => import('./../views/warehouse/WorkerManage/Role'),
        props: true //allow props to be passed
      },
      {
        path: '/worker-list',
        name: 'ListWorkers',
        // component: () => import('./../views/warehouse/WarehouseManageUser'),
        component: () => import('./../views/warehouse/WorkerManage/Workers'),
        props: true //allow props to be passed
      },
      { // TODO remove this later 
        path: '/operation/manage-user',
        name: 'rmaUserManage',
        // component: () => import('./../views/warehouse/WarehouseManageUser'),
        component: () => import('./../views/warehouse/WarehouseManageWorker'),
        props: true //allow props to be passed
      },
      {
        path: '/operation/dashboard',
        name: 'operationDashboard',
        // component: () => import('./../views/warehouse/WarehouseManageUser'),
        component: () => import( './../views/DashboardOperation'),
        props: true //allow props to be passed
      },
      {
        path: '/listRma',
        name: 'listRma',
        component: () => import( './../views/rma/listRma'),
      },
      {
        path: '/clientSearch',
        name: 'clientSearch',
        component: () => import( './../views/rma/clientSearch'),
      },
      {
        path: '/readRma',
        name: 'readRma',
        component: () => import( './../views/rma/readRma'),
      },
      {
        path: '/searchRma',
        name: 'SearchRma',
        component: () => import( './../views/customercare/searchRma'),
      },
      {
        path: '/createRma',
        name: 'CreateRma',
        component: () => import( './../views/rma/form/stepForm'),
      },
      {
        path: '/preparePackage',
        name: 'PreparePackage',  
        component: () => import( './../views/rma/preparePackage'),
      },
      {
        path: '/receivedpackage',
        name: 'ReceivedPackage',  
        component: () => import( './../views/rma/receivedPackage'),
      },
      {
        path: '/operation/listRma',
        name: 'operationlistRma',
        component: () => import( './../views/rma/listRmaOperation'),
      },
      {
        path: '/operation/rmafactory/login',
        name: 'operationfactoryLogin',
        component: () => import( './../views/warehouse/FactoryLogin'),
      },
      {
        path: '/system/systemConfig',
        name: 'systemConfig',
        component: () => import( './../views/system/systemConfig'),
      },
      {
        path: '/system/orderClear',
        name: 'orderClear',
        component: () => import( './../views/system/orderClear'),
      },
      {
        path: '/system/subReasonManage',
        name: 'subReasonManage',
        component: () => import( './../views/system/subReasonManage'),
      }
      
      /////////////////////////////////////////////////////
      /////////////////////////////////////////////////////
      /////////////////////////////////////////////////////
      /////////////////////   Users  //////////////////////
      /////////////////////////////////////////////////////
      /////////////////////////////////////////////////////
      /////////////////////////////////////////////////////
      // {
      //   path: "/users",
      //   name: "SearchRma",
      //   component: () => import( "./../views/customercare/searchRma"),
      // },
      // {
      //   path: "/searchUsers",
      //   name: "SearchRma",
      //   component: () => import( "./../views/customercare/searchRma"),
      // },

    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/customerStatus',
    name: 'CustomerStatus',
    component: CustomerStatus
  },
  {
    path: '/home',
    redirect: '/dashboard'
  },
  {
    path: '/documentation',
    name: 'Documentation',
    redirect: 'index',
    component: () => import( '../views/documentation/Documentation'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import( '../views/documentation/Index'),
      },
    ]
  }
]

export default routes
