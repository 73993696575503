import { createRouter, createWebHistory } from 'vue-router'
import routes  from './routes'
import storageUtils from '../utils/storage';
import useLockRepositories from "@/composables/auth/useLockRepositories"


const router = createRouter({
  history: createWebHistory(),
  routes
})
 
//could also be move to another file
//TODO implement actual service store check
router.beforeEach((to, from, next) => {

  const isLogged = storageUtils.isLogged();

  if(isLogged){
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' });
    }
    //pass
    next()
  }if (to.path === '/customerStatus'){
    next()
  }else{
    // console.log(to)
    if(to.path === '/login'){
      next()
    }
    //not logged
    next(`/login?redirect=${to.path}`);
  }

  // // Check route ->  operation/listRma weath user is logged when acces to Operation List all rma page
  // if (to.path === '/operation/listRma') {
  //   console.log("called to Operation route check")
  //   const { isWorkerLogged } = useLockRepositories()
  //   // console.log()
  //   // if is logged in, redirect to the home page
  //   if(!isWorkerLogged.value){
  //     console.log("(0kdncsdlk")
  //     //redirect to the random opeartion route - it will promt login
  //     next('/operation/4/RepairProcess/');
  //   }
  // }

   


})

export default router
 